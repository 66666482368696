import React, { forwardRef, useImperativeHandle, useEffect, useState } from "react";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import classes from './LeafletMap.module.css';
import MARKER from '../../assets/images/markerIcon.svg';
import INACTIVE_MARKER from '../../assets/images/markerIconInactive.svg';
import { useTranslation } from "react-i18next";

const LeafletMap = forwardRef((props, ref) => {
  const {
    markers,
    edges,
    zIndex,
    onMapClick,
    disablePopup,
    disableInteractions,
    disableFitBounds,
    allowPanTo,
    onSelectNarration,
    change,
    distance
  } = props;

  const { t } = useTranslation();

  const [map, setMap] = useState();
  const [circle, setCircle] = useState(null);
  const [restored, setRestored] = useState(false);

  useImperativeHandle(ref, () => ({
    centerMap(latLngBounds) {
      if (map) {
        map.setView(latLngBounds.getCenter());
      }
    },
    getMapInstance() {
      return map;
    }
  }));

  useEffect(() => {
    const savedCenter = localStorage.getItem('mapCenter');
    const savedZoom = localStorage.getItem('mapZoom');

    const tileLayer = L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
      attribution: '&copy; OpenStreetMap contributors &copy; CARTO',
      subdomains: 'abcd',
      maxZoom: 20
    });

    const mapInstance = L.map(`leaflet-map`, { zoomControl: false });
    tileLayer.addTo(mapInstance);

    if (!disableInteractions) {
      mapInstance.addControl(L.control.zoom({ position: 'bottomright' }));
    } else {
      mapInstance.dragging.disable();
      mapInstance.touchZoom.disable();
      mapInstance.doubleClickZoom.disable();
      mapInstance.scrollWheelZoom.disable();
      mapInstance.boxZoom.disable();
      mapInstance.keyboard.disable();
      if (mapInstance.tap) {
        mapInstance.tap.disable();
      }
    }

    if (savedCenter && savedZoom) {
      const center = JSON.parse(savedCenter);
      mapInstance.setView([center.lat, center.lng], savedZoom);
      setRestored(true);
      //console.log('Restored map center:', center);
    } else {
      mapInstance.setView([45.0578564352, 7.65664237342], 13);
      console.log('Set default map center');
    }

    setMap(mapInstance);
  }, [disableInteractions]);

  useEffect(() => {
    if (map) {
      map.on('moveend', () => {
        const center = map.getCenter();
        const zoom = map.getZoom();
        localStorage.setItem('mapCenter', JSON.stringify(center));
        localStorage.setItem('mapZoom', zoom);
      });
    }
  }, [map]);

  useEffect(() => {
    if (map) {
      map.off("click");
      map.on('click', function (ev) {
        var latlng = map.mouseEventToLatLng(ev.originalEvent);
        onMapClick(latlng);
        if (distance != null) {
          const radiusInMeters = distance * 1000;
          console.log('Distance (meters):', radiusInMeters);
          console.log('Latlng:', latlng);

          if (circle) {
            map.removeLayer(circle);
          }

          const newCircle = L.circle(latlng, {
            radius: radiusInMeters,
            color: '#F81A5C',
            fillColor: '#F81A5C',
            fillOpacity: 0.5
          }).addTo(map);
          setCircle(newCircle);
        }
      });
    }
  }, [map, onMapClick, distance]);

  const createPolyline = (edge) => {
    const latlngsFrom = edge.narrationFrom.locations.map(loc => [loc.lat, loc.lng]);
    const latlngsTo = edge.narrationTo.locations.map(loc => [loc.lat, loc.lng]);
    const latlngs = [...latlngsFrom, ...latlngsTo];
    const polyline = L.polyline(latlngs, { color: 'grey' }).addTo(map);

    edge.polyline = polyline;

    const entity1 = edge.narrationFrom;
    const entity2 = edge.narrationTo;
    const knowledgeDetails = edge.knowledge || [];
    const [fromId, toId] = edge.id.split('-');

    const popupContent = document.createElement('div');
    popupContent.innerHTML = `
      <div>
        Collegamento tra
        <a href="#" data-entity-id="${fromId}">${entity1.text}</a> 
        e 
        <a href="#" data-entity-id="${toId}">${entity2.text}</a>
      </div>
      <div style="margin-top: 10px;" id="entityDiv">
        <div>
        ${knowledgeDetails.map(kd => `<div><span style="font-weight: bold;">${t(`general.entities.${kd.label}`)}:</span> ${kd.text}</div>`).join('')}
      </div>`;

    const entityDiv = popupContent.querySelector('#entityDiv');
    entityDiv.addEventListener('click', () => {
      const selectedEdge = {
        id: edge.id,
        active: true,
        from: edge.from
      }
      sessionStorage.setItem('selectedEdge', JSON.stringify(selectedEdge));
      change("network");
    });

    popupContent.querySelectorAll('a').forEach(link => {
      link.addEventListener('click', (e) => {
        e.preventDefault();
        const entityId = e.target.getAttribute('data-entity-id');
        console.log('Entity ID:', entityId);
        const narration = {
          id: entityId,
        }
        onSelectNarration(narration);
      });
    });

    polyline.bindPopup(popupContent);

    polyline.on('click', function (e) {
      const popup = this.getPopup();
      popup.setLatLng(e.latlng).openOn(map);
    });
  };

  useEffect(() => {
    if (map) {
      map.eachLayer((layer) => {
        if (!(layer instanceof L.TileLayer)) {
          map.removeLayer(layer);
        }
      });
    }

    if (map && markers && markers.length > 0) {
      const icon = L.icon({
        iconUrl: INACTIVE_MARKER,
        iconSize: [20, 20], // size of the icon
      });
      const activeIcon = L.icon({
        iconUrl: MARKER,
        iconSize: [25, 25], // size of the icon
      });

      const markersCluster = L.markerClusterGroup({
        spiderfyOnMaxZoom: true,
        disableClusteringAtZoom: 20,
        showCoverageOnHover: false,
        zoomToBoundsOnClick: true,
        maxClusterRadius: 20,
        iconCreateFunction: function (cluster) {
          const count = cluster.getChildCount();
          const maxCount = 50;
          const minShade = 100;
          const maxShade = 200;
          const shade = Math.min(maxShade, Math.floor(maxShade - (count / maxCount) * (maxShade - minShade)));
          const color = `rgb(${shade}, ${shade}, ${shade})`;
          const hasSelectedMarker = cluster.getAllChildMarkers().some(marker => marker.options.icon.options.iconUrl === MARKER);
          const borderColor = hasSelectedMarker ? '#F81A5C' : color;
          const textColor = shade < 150 ? '#FFFFFF' : '#000000'; // Set text color to white if shade is too dark

          return L.divIcon({
            html: `<div style="background-color: ${color}; border: 2px solid ${borderColor}; border-radius: 50%; width: 30px; height: 30px; display: flex; align-items: center; justify-content: center; color: ${textColor};">${count}</div>`,
            className: 'marker-cluster',
            iconSize: L.point(20, 20)
          });
        }
      });

      for (const m of markers) {
        const marker = L.marker([m.coordinates.lat, m.coordinates.lng], { icon: icon });
        if (!disablePopup && m.popup) {
          marker.bindPopup(m.popup, {
            closeButton: false,
            maxWidth: 200
          });
          marker.on('mouseover', function (e) {
            marker.setIcon(activeIcon);

            edges.forEach(edge => {
              if (edge.from === m.narration || edge.to === m.narration) {
                map.removeLayer(edge.polyline);
                createPolyline(edge);
                edge.polyline.setStyle({ color: '#f82e6a' });
              } else {
                edge.polyline.setStyle({ color: 'grey' });
              }
            });
          });
          marker.on('click', function (e) {
            marker.setIcon(activeIcon);
            this.openPopup();
          });
          marker.on('mouseout', function (e) {
            if (!m.selected) {
              marker.setIcon(icon);
            }
            edges.forEach(edge => {
              if (edge.from === m.narration || edge.to === m.narration) {
                map.removeLayer(edge.polyline);
                createPolyline(edge);
                edge.polyline.setStyle({ color: 'grey' });
              }
            });
          });
          if (m.selected) {
            marker.setIcon(activeIcon);
            marker.openPopup();
          }
        }
        markersCluster.addLayer(marker);
      }

      map.addLayer(markersCluster);

      if (!disableFitBounds && !restored) {
        map.fitBounds(markers.map(el => el.coordinates), { padding: [10, 10] });
      }

      if (allowPanTo) {
        map.panTo(markers[0].coordinates);
      }
    }
  }, [markers, edges, map, disablePopup, disableFitBounds, allowPanTo, restored]);

  useEffect(() => {
    if (map && edges && edges.length > 0) {
      for (const edge of edges) {
        createPolyline(edge);
      }
    }
  }, [markers, edges, map, disablePopup, disableFitBounds, allowPanTo, onSelectNarration]);

  return (
      <div id={`leaflet-map`} className={classes.LeafletMap} style={{ zIndex: zIndex }}>
      </div>
  );
});

export default LeafletMap;
import React, { useState } from "react";
import ReactDOM from "react-dom";
import ReactDOMServer from "react-dom/server";
import { useTranslation } from "react-i18next";
import { getKnowledgeItem } from "../../utils/omekaSManager";
import { foreignObjectBorders } from "../Graphs/NetworkGraph/CustomNode/CustomNode";
import Network from "../Network/Network";
import classes from './EntitiesNetwork.module.css';
import CLOSE_ICON from '../../assets/images/close.svg';
import GraphsSidebar from "../GraphsSidebar/GraphsSidebar";
import Graph from "graphology";
import Sigma from "sigma";
import {circular} from 'graphology-layout';
import FA2Layout from "graphology-layout-forceatlas2";
import EntityNetwork from "../EntityNetwork/EntityNetwork";

let graph = new Graph();
let sigmaInstance;
let labelCount = 0;

/*
const getCustomNodeHeight = (data) => {
    const width = getCustomNodeWidth();
    const el = (<CustomNodeContent data={data} width={width} />);
    const div = document.createElement("div");
    const htmlString = ReactDOMServer.renderToStaticMarkup(el);
    div.innerHTML = htmlString;
    document.body.appendChild(div);
    const height = div.clientHeight;
    document.body.removeChild(div);
    return height + 2 * foreignObjectBorders.y * 10;
}


const getCustomNodeWidth = () => {
    return 450;
}

const CustomNodeContent = (props) => {
    const { id, width, selected, hovered, data } = props;
    const ref = React.createRef();
    const [isLongText, setIsLongText] = React.useState(false);

    return (
        <div id={id} data-hover={selected || hovered} className={classes.NodeContainer} style={{ width: width ? width : '100%' }}>
            <p className={classes.Label}>{data ? data.label : "-"}</p>
            <p ref={ref} className={[classes.Text, isLongText ? classes.OverflowText : ''].join(" ")}>{data ? data.txt : "-"}</p>
        </div>
    );
};
*/

const EntitiesNetwork = (props) => {
    const {
        narrations,
        knowledge
    } = props;
    const { t } = useTranslation();
    const [nodes, setNodes] = React.useState([]);
    const [nodesOrderedBySize, setNodesOrderedBySize] = React.useState([]);
    const [edges, setEdges] = React.useState([]);
    const [graphType, setGraphType] = React.useState('1');
    const [state, setState] = React.useState({
        hoveredNode: undefined,
        searchQuery: "",
        selectedNode: undefined,
        suggestions: undefined,
        hoveredNeighbors: undefined,
        zoomLevel: 1
    });

    const [showPopup, setShowPopup] = useState(false);
    const [selectedEntity, setSelectedEntity] = useState(null);
    const [labelColorMap, setLabelColorMap] = useState({});
    const [labelCounts, setLabelCounts] = useState({});
    /**
     * Custom label renderer
     */
    function drawLabel(context, data, settings) {
        if (!data.label && !data.text) return;

        const size = settings.labelSize * 0.85, // Decrease font size
            font = settings.labelFont,
            weight = settings.labelWeight;

        context.font = `${weight} ${size}px ${font}`;

        // Wrap text after 20 characters
        const wrapText = (text, maxLength) => {
            const words = text.split(' ');
            let lines = [];
            let currentLine = '';

            words.forEach(word => {
                if ((currentLine + word).length > maxLength) {
                    lines.push(currentLine);
                    currentLine = word + ' ';
                } else {
                    currentLine += word + ' ';
                }
            });

            lines.push(currentLine.trim());

            // Limit to 2 lines and add ellipsis if necessary
            if (lines.length > 2) {
                lines = lines.slice(0, 2);
                lines[1] = lines[1] + '...';
            }

            return lines;
        };

        const labelLines = wrapText(data.label, 50);
        const combinedText = [...labelLines, data.tipologia];
        const lineHeight = size + 2;
        const width = Math.max(...combinedText.map(line => context.measureText(line).width)) + 8;
        const height = combinedText.length * lineHeight;

        context.fillStyle = "#ffffffcc";
        context.fillRect(data.x + data.size, data.y - height / 2, width, height);

        context.fillStyle = "#000";
        combinedText.forEach((line, index) => {
            if (index === combinedText.length - 1) {
                context.font = `bold ${size}px ${font}`; // Set bold font for tipologia
            } else {
                context.font = `${weight} ${size}px ${font}`;
            }
            context.fillText(line, data.x + data.size + 3, data.y - height / 2 + (index + 1) * lineHeight - 2);
        });
    }

    //Legend
    const Legend = ({ labelColorMap, labelCounts }) => {
        return (
            <div className={classes.legendContainer}>
                {Object.keys(labelColorMap).map(label => (
                    <div key={label} className={classes.legendItem}>
                    <span
                        className={classes.legendColor}
                        style={{ backgroundColor: labelColorMap[label] }}
                    ></span>
                        <span className={classes.legendLabel}>{t("general.entities." + label)} ({labelCounts[label]})</span>
                    </div>
                ))}
            </div>
        );
    };

    React.useEffect(() => {
        console.log("Ordered by size", nodesOrderedBySize);
    } , [nodesOrderedBySize]);

    React.useEffect(() => {
        if (!knowledge || knowledge.length === 0) {
            return;
        }

        const uniqueLabels = [...new Set(knowledge.map(entity => entity.label))];
        const labelIndices = uniqueLabels.reduce((acc, label) => {
            acc[label] = 0;
            return acc;
        }, {});

        const colors = ["#FF5733", "#33FF57", "#3357FF", "#006400", "#A133FF", "#33FFF5", "#F5FF33", "#FFB833", "#838996", "#80FF33"];
        const labelColorMap = uniqueLabels.reduce((acc, label, index) => {
            acc[label] = colors[index % colors.length];
            return acc;
        }, {});

        setLabelColorMap(labelColorMap);

        const labelCounts = uniqueLabels.reduce((acc, label) => {
            acc[label] = 0;
            return acc;
        }, {});

        const nodes = knowledge.map((entity, index) => {
            labelIndices[entity.label] += 1;
            labelCounts[entity.label] += 1;

            return {
                id: entity.id,
                label: entity.label,
                text: entity.text,
                height: 100,
                width: 100,
                data: entity,
                color: labelColorMap[entity.label]
            };
        });
        //console.log("Nodes", nodes);
        setLabelCounts(labelCounts);

        const edgeSet = new Set();
        const edges = [];

        knowledge.forEach(entity => {
            entity.knowledgeRelations.forEach(rel => {
                const edgeId = `${entity.id}-${rel.id}`;
                if (!edgeSet.has(edgeId)) {
                    edgeSet.add(edgeId);
                    edges.push({
                        id: edgeId,
                        from: entity.id,
                        to: rel.id,
                        data: rel.rel
                    });
                }
            });
        });
        //console.log("Edges", edges);

        setNodes(nodes);
        setEdges(edges);
        nodes.forEach(node => {
            if (!graph.hasNode(node.id)) {
                graph.addNode(node.id, { label: node.text, size: 3, color: node.color,tipologia: t("general.entities." + node.label) });
            }
        });
        edges.forEach(edge => {
            if (graph.hasNode(edge.from) && graph.hasNode(edge.to)) {
                if (!graph.hasEdge(edge.from, edge.to)) {
                    graph.addEdge(edge.from, edge.to);
                }
            } else {
                console.error(`Node not found: ${!graph.hasNode(edge.from) ? edge.from : edge.to}`);
            }
        });
        graph.nodes().forEach(nodeId => {
            const degree = graph.degree(nodeId);
            if (degree > 0) {
                graph.setNodeAttribute(nodeId, 'size', Math.min(3 + degree, 10));
            }
        });

        setNodesOrderedBySize(graph.nodes().map(nodeId => ({ id: nodeId, size: graph.getNodeAttribute(nodeId, 'size') })).sort((a, b) => b.size - a.size));

        for (let i = 0; i < 10; i++) {
            const nodeId = graph.nodes()[0];
            if (nodeId) {
                graph.dropNode(nodeId);
            }
        }

        circular.assign(graph);

        // Apply ForceAtlas2 layout
        FA2Layout.assign(graph, {
            iterations: 100,
        });

        sigmaInstance = new Sigma(graph, document.getElementById('graph-container'), {
            defaultDrawNodeLabel: drawLabel,
            defaultDrawNodeHover: drawLabel,
        });

        const setHoveredNode = (node) => {
            setState((prevState) => ({
                ...prevState,
                hoveredNode: node,
                hoveredNeighbors: node ? new Set(graph.neighbors(node)) : undefined
            }));
            sigmaInstance.refresh({ skipIndexation: true });
        };

        sigmaInstance.on("enterNode", ({ node }) => {
            setHoveredNode(node);
        });

        sigmaInstance.on("leaveNode", () => {
            setHoveredNode(undefined);
        });

        sigmaInstance.getCamera().on("updated", () => {
            setState((prevState) => ({
                ...prevState,
                zoomLevel: sigmaInstance.getCamera().getState().ratio
            }));
        });

        //al click su un nodo
        sigmaInstance.on("clickNode", ({ node }) => {
            setSelectedEntity(node);
            setShowPopup(true);
        });

    }, [knowledge]);

    React.useEffect(() => {
        if (sigmaInstance) {
            sigmaInstance.refresh();
        }
    }, [state]);

    React.useEffect(() => {
        return () => {
            if (sigmaInstance) {
                sigmaInstance.kill();
                sigmaInstance = null;
            }
        };
    }, []);

    React.useEffect(() => {
        console.log("ZOom level", state.zoomLevel);
    } , [state.zoomLevel]);

    React.useEffect(() => {
        if (sigmaInstance) {
            console.log("Hovered Node", state.hoveredNode);

            const getConnectedNodeLabels = (node) => {
                const neighbors = graph.neighbors(node);
                return Array.from(neighbors).map(neighbor => graph.getNodeAttribute(neighbor, 'label')).join(', ');
            };

            sigmaInstance.setSetting("nodeReducer", (node, data) => {
                const res = { ...data };
                if (state.hoveredNode) {
                    if (state.hoveredNode !== node && !state.hoveredNeighbors?.has(node)) {
                        res.hidden = true;
                    } else {
                        //res.label = `${data.label} (Connected: ${getConnectedNodeLabels(node)})`;
                        //console.log("Node", node);
                        //console.log("Data", data);
                        res.label = `${data.label}`;
                    }
                } else {
                    if (state.zoomLevel >= 0.21)
                    {
                        if (nodesOrderedBySize.findIndex(el => el.id === node) < 10) {
                            res.label = `${data.label}`;
                        } else {
                            res.label = '';
                        }
                    } else if (0.09<= state.zoomLevel && state.zoomLevel < 0.21) {
                        if (nodesOrderedBySize.findIndex(el => el.id === node) < 50) {
                            res.label = `${data.label}`;
                        } else {
                            res.label = '';
                        }
                    }   else if (0.05<= state.zoomLevel && state.zoomLevel < 0.09) {
                        if (nodesOrderedBySize.findIndex(el => el.id === node) < 100) {
                            res.label = `${data.label}`;
                        } else {
                            res.label = '';
                        }
                    } else {
                        res.label = `${data.label}`;
                    }
                    /*
                    if (data.size*0.3 >= sizeThreshold) {
                        res.label = `${data.label}`;
                    } else {
                        res.label = '';
                    }

                     */

                }
                return res;
            });

            sigmaInstance.setSetting("edgeReducer", (edge, data) => {
                const res = { ...data };
                if (state.hoveredNode) {
                    const [source, target] = graph.extremities(edge);
                    if (source !== state.hoveredNode && target !== state.hoveredNode) {
                        res.hidden = true;
                    }
                }
                return res;
            });

            sigmaInstance.refresh();
        }
    }, [state]);

    const renderPopup = React.useCallback((popup) => {
        let data;
        try {
            data = JSON.parse(popup.data);
        } catch (error) {
            console.error("Invalid JSON data:", popup.data);
            return null;
        }

        return (
            <div style={{ position: 'absolute', left: popup.position.x, top: popup.position.y, backgroundColor: 'var(--primary-light)', transform: 'translate(-50%, -50%)', pointerEvents: 'none', margin: 0, padding: '.5rem', borderRadius: '.5rem' }}>
                {data.map((el, index) => {
                    const knowledgeFilteredData = knowledge.filter(knEl => knEl.id === el);
                    const knowledgeData = knowledgeFilteredData && knowledgeFilteredData.length > 0 ? knowledgeFilteredData[0] : null;
                    return knowledgeData ? (
                        <div key={"popup-key-" + el} style={index < data.length - 1 ? { marginBottom: '.5rem' } : {}}>
                            <p style={{ marginTop: 0, marginBottom: '.25rem', textTransform: 'capitalize', color: 'var(--text)', fontSize: 'x-small', fontWeight: 'bold' }}>{t('general.entities.' + knowledgeData.label)}</p>
                            <p style={{ margin: 0, color: 'var(--primary)', fontSize: 'smaller' }}>{knowledgeData.text}</p>
                        </div>
                    ) : null;
                })}
            </div>
        );
    }, [knowledge, t]);

    return (
        <div style={{ width: '100%', height: '100%' }}>
            {showPopup && (
                <EntityNetwork
                    selectedEntity={selectedEntity}
                    knowledge={knowledge}
                    narrations={narrations}
                    onClose={() => setShowPopup(false)}
                />
            )}

            <div className={classes.Wrapper}>
                {/*<GraphsSidebar onSelectGraphType={setGraphType} />*/}
                {graphType === '1' && <div id="graph-container" style={{ width: '100%', height: '100%' }}></div>}
                {/*graphType === '2' &&
                    <Network
                        nodes={nodes}
                        edges={edges}
                        renderPopup={(popup) => renderPopup(popup)}
                        NodeComponent={CustomNodeContent}
                    />
                */}
            </div>
            <Legend
                labelColorMap={labelColorMap}
                labelCounts={labelCounts}
            />
        </div>
    );
};

export default EntitiesNetwork;
import React, { useEffect, useState } from "react";

import classes from './NarrationsList.module.css';

const NarrationsList = (props) => {
    const {
        narrations,
        onSelectNarration,
    } = props;

    return (
        <div className={classes.NarrationsList}>
            {narrations.map(narration => (
                <div key={narration['id']} className={classes.NarrationItem} onClick={() => onSelectNarration(narration)}>
                    <p>{narration.text}</p>
                </div>
            ))}
        </div>
    );
}

export default NarrationsList;
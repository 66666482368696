import React from "react";
import {useTranslation} from "react-i18next";
import Dropdown from "../Dropdown/Dropdown";

import DELETE_RELATION from '../../assets/images/delete.svg';
import ADD_RELATION from '../../assets/images/add.svg';

import classes from './AddRelations.module.css';
import {v4} from "uuid";
import {getCommentFromClassOrProperty} from "../../utils/omekaSManager";

const relationObjectProps = {
  UUID: 'uuid',
  FROM: 'from',
  RELATION: 'relation',
  TO: 'to'
};

const AddRelations = React.forwardRef((props, ref) => {

  const {
    relations,
    entities,
    insertedRelations,
    onInsertRelation,
    onRemoveRelation,
    onEditRelation,
    relationDropdownDisabled,
    omekaSClasses,
    omekaSProperties
  } = props;

  const {t} = useTranslation();

  const [currentFromEntity, setCurrentFromEntity] = React.useState();
  const [currentRelation, setCurrentRelation] = React.useState();
  const [currentToEntity, setCurrentToEntity] = React.useState();

  const setEntitiesArrayForDropdown = React.useCallback((allowedEntities = null, isLeftOperand = false) => {
    return entities
        .filter(el => {
          return isLeftOperand ? el.entityOfNarration : true
        })
        .map(e => {
          return {
            label: `${e.selection.text}\n(${t("general.entities." + e.entity)})`,
            value: e.uuid
          }
        })
  }, [entities, t]);

  const setRelationsArrayForDropdown = React.useCallback((currentFromEntityClass) => {
    return relations
        .filter(r => !r.domain || r.domain.some(d => d.label === currentFromEntityClass))
        .map((r) => {
          return {
            label: t('general.relations.' + r.label),
            value: {
              author: r.author,
              from: r.from,
              label: r.label,
              property: r.property,
              to: r.to,
              title: getCommentFromClassOrProperty(omekaSProperties, r.property),
              range: r.range,
              domain: r.domain
            }
          }
        })
  }, [t, relations, omekaSProperties]);

  const setEntitiesArrayForToDropdown = React.useCallback((currentRelation) => {
    //console.log("currentRelation", currentRelation);
    //console.log("entities", entities);
    if (!currentRelation) return [];
    return entities
        .filter(e => !currentRelation.range || currentRelation.range.some(r => r.label === e.entity))
        .map(e => {
          return {
            label: `${e.selection.text}\n(${t("general.entities." + e.entity)})`,
            value: e.uuid
          }
        })
  }, [entities, t]);

  const findEntityIndexValue = React.useCallback((entity, allowedEntities = null, isLeftOperand = false) => {
    if (entity) {
      const items = setEntitiesArrayForDropdown(allowedEntities, isLeftOperand);
      for (const [index, ent] of Object.entries(items)) {
        if (entity === ent.value) {
          return parseInt(index);
        }
      }
    }
    return -1;
  }, [setEntitiesArrayForDropdown]);

  const findEntityToIndexValue = React.useCallback((entity, currentRelation) => {
    if (entity) {
      console.log("entity", entity);
      const items = setEntitiesArrayForToDropdown(currentRelation);
      for (const [index, ent] of Object.entries(items)) {
        if (entity === ent.value) {
          console.log("index", index);
          return parseInt(index);
        }
      }
    }
    return -1;
  }, [setEntitiesArrayForToDropdown]);

  const findRelationIndexValue = React.useCallback((relation, currentFromEntityClass) => {
    const items = setRelationsArrayForDropdown(currentFromEntityClass);
    if (relation) {
      for (const [index, rel] of Object.entries(items)) {
        if (relation.property === rel.value.property) {
          return parseInt(index);
        }
      }
    }
    return -1;
  }, [setRelationsArrayForDropdown]);

  const onChangeCurrentRelation = React.useCallback((value) => {
    setCurrentRelation(value);
  }, []);

  const onChangeCurrentFromValue = React.useCallback((value) => {
    setCurrentFromEntity(value);
    setCurrentRelation(null);
    setCurrentToEntity(null);
  }, []);

  const onChangeCurrentToValue = React.useCallback((value) => {
    console.log("onChangeCurrentToValue", value);
    setCurrentToEntity(value);
  }, []);

  const editRelation = React.useCallback((index, prop, newValue) => {
    const item = {...insertedRelations[index]};
    if (prop === relationObjectProps.RELATION) {

      const fromValue = item[relationObjectProps.FROM];
      const toValue = item[relationObjectProps.TO];

      const allowedFromEntities = newValue.from.map(e => e.label);
      const allowedToEntities = newValue.to.map(e => e.label);

      if (fromValue) {
        if (!allowedFromEntities.includes(fromValue.entity)) {
          item[relationObjectProps.FROM] = null;
        }
      }
      if (toValue) {
        if (!allowedToEntities.includes(toValue.entity)) {
          item[relationObjectProps.TO] = null;
        }
      }

    }
    item[prop] = newValue;
    onEditRelation(item, index);
  }, [insertedRelations, onEditRelation])

  const insertRelation = React.useCallback(() => {
    if (currentFromEntity && currentRelation && currentToEntity) {
      const newRelation = {};
      newRelation[relationObjectProps.UUID] = v4();
      newRelation[relationObjectProps.RELATION] = currentRelation;
      newRelation[relationObjectProps.FROM] = currentFromEntity;
      newRelation[relationObjectProps.TO] = currentToEntity;
      onInsertRelation(newRelation);
      setCurrentFromEntity(null);
      setCurrentRelation(null);
      setCurrentToEntity(null);
    } else {
      console.log("ERRORE");
    }
  }, [currentFromEntity, currentRelation, currentToEntity, onInsertRelation]);

  return (
      <div ref={ref} className={classes.Container}>
        <div className={classes.InfoContainer}>
          <p className={classes.InfoTitle}>{t('author.addRelations.add')}</p>
          <p className={[classes.InfoSubTitle, classes.Info].join(" ")}>{t('author.addRelations.relateEntities')}</p>
          <p className={classes.InfoText}>{t('author.addRelations.textInfo1')}</p>
          <p className={classes.InfoText}>{t('author.addRelations.textInfo2')}</p>
        </div>
        <div className={classes.RelationsContainer}>
          <div className={classes.Box}>
            {
              insertedRelations.map((rel, index) => {
                const currentFromEntityClass = entities.find(e => e.uuid === rel.from)?.entity;
                return (
                    <div key={"inserted-relation-from-entity-dropdown" + index}
                         className={classes.RelationBox}>
                      <div className={classes.DropdownColumn}>
                        <Dropdown
                            id={"inserted-relation-from-entity-dropdown" + index}
                            placeholder={t("author.addRelations.selectEntity")}
                            value={findEntityIndexValue(rel.from, rel.relation.from)}
                            elements={setEntitiesArrayForDropdown(rel.relation.from, true)}
                            onSelectValue={(value) => editRelation(index, relationObjectProps.FROM, value)}
                        />
                      </div>
                      <div className={classes.DropdownColumn}>
                        <Dropdown
                            id={"inserted-relation-relation-dropdown" + index}
                            placeholder={t("author.addRelations.selectRelation")}
                            value={findRelationIndexValue(rel.relation, currentFromEntityClass)}
                            elements={setRelationsArrayForDropdown(currentFromEntityClass)}
                            outlined
                            onSelectValue={(value) => editRelation(index, relationObjectProps.RELATION, value)}
                            disabled={relationDropdownDisabled}
                        />
                      </div>
                      <div className={classes.DropdownColumn}>
                        <Dropdown
                            id={"inserted-relation-to-entity-dropdown-" + index}
                            placeholder={t("author.addRelations.selectEntity")}
                            value={findEntityIndexValue(rel.to, rel.relation.to)}
                            elements={setEntitiesArrayForDropdown(rel.relation.to)}
                            onSelectValue={(value) => editRelation(index, relationObjectProps.TO, value)}
                        />
                      </div>
                      <div className={classes.IconContainer}>
                        <img
                            src={DELETE_RELATION} alt=""
                            className={classes.DeleteRelationIcon}
                            onClick={() => onRemoveRelation(index)}
                        />
                      </div>
                    </div>
                )
              })
            }
            <div className={classes.RelationBox}>
              <div className={classes.DropdownColumn}>
                <Dropdown
                    id="from-entity-dropdown"
                    placeholder={t("author.addRelations.selectEntity")}
                    value={findEntityIndexValue(currentFromEntity, currentRelation ? currentRelation.from : null, true)}
                    elements={setEntitiesArrayForDropdown(currentRelation ? currentRelation.from : null, true)}
                    onSelectValue={(value) => onChangeCurrentFromValue(value)}
                />
              </div>
              <div className={classes.DropdownColumn}>
                <Dropdown
                    id="relation-dropdown"
                    placeholder={t("author.addRelations.selectRelation")}
                    value={findRelationIndexValue(currentRelation, currentFromEntity ? entities.find(e => e.uuid === currentFromEntity)?.entity : null)}
                    elements={setRelationsArrayForDropdown(currentFromEntity ? entities.find(e => e.uuid === currentFromEntity)?.entity : null)}
                    outlined
                    onSelectValue={(value) => onChangeCurrentRelation(value)}
                />
              </div>
              <div className={classes.DropdownColumn}>
                <Dropdown
                    id="to-entity-dropdown"
                    placeholder={t("author.addRelations.selectEntity")}
                    value={findEntityToIndexValue(currentToEntity, currentRelation ? currentRelation : null)}
                    elements={setEntitiesArrayForToDropdown(currentRelation)}
                    onSelectValue={(value) => onChangeCurrentToValue(value)}
                />
              </div>
              <div className={classes.IconContainer}>
                <p
                    className={
                      currentFromEntity && currentRelation && currentToEntity ?
                          [classes.AddRelationIcon, classes.Allowed].join(" ") :
                          [classes.AddRelationIcon, classes.Disabled].join(" ")
                    }
                    onClick={() => insertRelation()}
                >&#10004;</p>
              </div>
            </div>
          </div>
        </div>
      </div>
  );

});

export default AddRelations;
import React from "react";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Fragment from "../Fragment/Fragment";

import DRAG_FRAGMENT from '../../assets/images/dragFragment.svg';
import DELETE_FRAGMENT from '../../assets/images/delete.svg';
import EDIT_FRAGMENT from '../../assets/images/edit.svg';

import classes from './InsertedFragment.module.css';
import { useTranslation } from "react-i18next";

const InsertedFragment = (props) => {

  const {
    existingSources,
    insertedFragments,
    insertedSources,
    onMoveFragment,
    onRemoveFragment,
    onEditFragment,
    omekaSClasses,
    omekaSProperties
  } = props;

  //console.log("insertedFragments", insertedFragments);

  const { t } = useTranslation();

  const [fragmentToEdit, setFragmentToEdit] = React.useState();
  const [importantFragment, setImportantFragment] = React.useState(null);

  const handleOnDragEnd = React.useCallback((result) => {
    if(result.destination){
      const items = Array.from(insertedFragments);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      onMoveFragment(items);
    }
  }, [insertedFragments, onMoveFragment]);

  const handleCheckboxChange = (index, fragment) => {
    const updatedFragments = [...insertedFragments];
    if (!updatedFragments[index].pivot) {
      updatedFragments[index].pivot = [{
        "type": "literal",
        "property_id": 967,
        "property_label": "perno",
        "is_public": true,
        "@value": "false"
      }];
    }
    updatedFragments.forEach((f, i) => {
      if (i !== index && f.pivot) {
        f.pivot[0]['@value'] = "false";
      }
    });
    updatedFragments[index].pivot[0]['@value'] = "true";
    onEditFragment(updatedFragments[index], index);
    setImportantFragment(fragment);
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="inserted-fragments-id">
        {
          (provided) => (
            <div
              {...provided.droppableProps}
              ref= {provided.innerRef}
            >
              {
                insertedFragments.map((f, index) => {
                  return (
                    <Draggable
                      key={"unique-key-inserted-fragment-"+f+"-"+index}
                      draggableId={"unique-key-inserted-fragment-"+f+"-"+index}
                      index={index}
                    >
                      {
                        (provided) => {
                          if(fragmentToEdit && fragmentToEdit.fragment === f.fragment){
                            return (
                              <div
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref= {provided.innerRef}
                                className={classes.InsertedFragmentToEdit}
                              >
                                <Fragment
                                  omekaSClasses={omekaSClasses}
                                  omekaSProperties={omekaSProperties}
                                  key={"unique-key-fragment-draggable-"+f.fragment}
                                  existingSources={existingSources}
                                  insertedSources={insertedSources}
                                  fragment={f.fragment}
                                  fragmentToEdit={(fragmentToEdit && fragmentToEdit.fragment === f.fragment) ? fragmentToEdit : null}
                                  onAddFragment={(fragment) => {
                                    if(fragmentToEdit && fragmentToEdit.fragment === f.fragment){
                                      setFragmentToEdit(null);
                                    }
                                    onEditFragment(fragment, index)}
                                  }
                                  onDeleteEdit={() => setFragmentToEdit(null)}
                                />
                              </div>
                            )
                          }
                          else{
                            return (
                          <div
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref= {provided.innerRef}
                            className={classes.InsertedFragment}
                          >
                            <div className={classes.DragIconContainer}>
                              <img src={DRAG_FRAGMENT} alt="" />
                            </div>
                            <div className={classes.InsertedFragmentContainer}>
                              <p className={classes.InsertedFragmentTitle}>{t('general.fragments.'+f.fragment)}</p>
                              <p className={classes.InsertedFragmentContent}>{f.text}</p>
                            </div>
                            <div className={classes.EditDeleteIconContainer}>
                              {f.fragment !== "location" && (
                                  <input
                                      type="checkbox"
                                      checked={f.pivot?.[0]?.['@value'] === "true" ?? false}
                                      onChange={() => handleCheckboxChange(index, f.fragment)}
                                  />
                              )}
                              <img
                                  src={EDIT_FRAGMENT} alt=""
                                  onClick={() => {
                                    setFragmentToEdit({...insertedFragments[index]});
                                  }}
                              />
                              <img
                                src={DELETE_FRAGMENT} alt=""
                                onClick={() => onRemoveFragment(index)}
                              />
                            </div>
                          </div>)
                          }

                        }
                      }
                    </Draggable>
                  )
                })
              }
              { provided.placeholder }
            </div>
          )
        }
      </Droppable>
    </DragDropContext>
  );

};

export default InsertedFragment;
import React, { useEffect, useImperativeHandle, forwardRef, useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';

import classes from './EntitiesMap.module.css';
import MARKER from '../../assets/images/markerIcon.svg';
import INACTIVE_MARKER from '../../assets/images/markerIconInactive.svg';

const EntitiesMap = forwardRef((props, ref) => {
    const {
        narrations,
        knowledge,
    } = props;

    const [map, setMap] = useState(null);

    useImperativeHandle(ref, () => ({
        centerMap(latLngBounds) {
            if (map) {
                map.setView(latLngBounds.getCenter());
            }
        },
        getMapInstance() {
            return map;
        }
    }));

    useEffect(() => {
        if (!map) {
            const mapInstance = L.map('leaflet-map', { zoomControl: false }).setView([44.20279874695161, 7.575759887695313], 13);
            L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
                attribution: '&copy; OpenStreetMap contributors &copy; CARTO',
                subdomains: 'abcd',
                maxZoom: 20
            }).addTo(mapInstance);
            setMap(mapInstance);
        }
    }, [map]);

    useEffect(() => {
        if (map) {
            const uniqueLocations = narrations.reduce((acc, narration) => {
                narration.locations.forEach(location => {
                    if (!acc.some(loc => loc.id === location.entityId)) {
                        acc.push({
                            id: location.entityId,
                            name: location.entity,
                            lat: parseFloat(location.lat),
                            lng: parseFloat(location.lng),
                        });
                    }
                });
                return acc;
            }, []);

            const offset = 0.0001; // Base offset to slightly change coordinates

            knowledge.forEach((item, index) => {
                const hasLocation = item.knowledgeRelations.some(rel => rel.rel === 'hasCurrentPermanentLocation');
                const isLocationOf = item.knowledgeRelations.some(rel => rel.rel === 'isCurrentPermanentLocationOf');
                if (hasLocation || isLocationOf) {
                    const locationRelation = item.knowledgeRelations.find(rel => rel.rel === 'hasCurrentPermanentLocation' || rel.rel === 'isCurrentPermanentLocationOf');
                    const relatedLocation = narrations.flatMap(narration => narration.locations).find(loc => loc.entityId === locationRelation.id);
                    if (relatedLocation) {
                        uniqueLocations.push({
                            id: item.id,
                            name: item.text,
                            lat: parseFloat(relatedLocation.lat) + offset * (index + 1),
                            lng: parseFloat(relatedLocation.lng) + offset * (index + 1)
                        });
                    }
                }
            });

            const inactiveIcon = L.icon({
                iconUrl: INACTIVE_MARKER,
                iconSize: [20, 20], // size of the icon
                iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
                popupAnchor: [1, -34], // point from which the popup should open relative to the iconAnchor
            });

            uniqueLocations.forEach(location => {
                L.marker([location.lat, location.lng], { icon: inactiveIcon })
                    .addTo(map)
                    .bindPopup(`<b>${location.name}</b>`);
            });
        }
    }, [map, narrations, knowledge]);

    return (
        <div>
            <div id={`leaflet-map`} className={classes.LeafletMap}></div>
        </div>
    );
});

export default EntitiesMap;
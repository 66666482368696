export const doubleClickDelay = 0.5;

export const roles = {
  ADMINISTRATOR: 0,
  EDITOR: 1,
  GUEST: 2
};

export const FEEDBACK_STATUSES = {
  SUCCESS: "success",
  PARTIAL_SUCCESS: "partialSuccess",
  ERROR: "error"
};

export const VIZ_TYPES = {
  MAP: 'map',
  NARRATIONS_MAP: 'narrations-map',
  ENTITIES_MAP: 'entities-map',
  SOURCES_MAP: 'sources-map',
  NARRATIONS_NETWORK: 'narrations-network',
  ENTITIES_NETWORK: 'entities-network',
  SOURCES_NETWORK: 'sources-network',
  NARRATIONS: 'narrations',
  ENTITIES: 'entities',
  SOURCES: 'sources',
  NETWORK: 'network',
  TIMELINE: 'timeline',
};

export const SEARCH_TYPES = {
  NARRATION: "narration",
  NARRATOR: "narrator",
  FRAGMENT: "fragment",
  KNOWLEDGE: "entity",
  SOURCE_REFERENCE: "sourceReference",
  SOURCE: "source"
};

export const SEARCH_TYPE_PERMISSIONS = {
  NARRATION: [SEARCH_TYPES.NARRATION],
  NARRATOR: [SEARCH_TYPES.NARRATOR],
  FRAGMENT: [SEARCH_TYPES.FRAGMENT],
  KNOWLEDGE: [SEARCH_TYPES.KNOWLEDGE],
  SOURCE_REFERENCE: [SEARCH_TYPES.SOURCE_REFERENCE],
  SOURCE: [SEARCH_TYPES.SOURCE]
}

const hsg = "http://purl.org/hsg";
const geo = "http://www.w3.org/2003/01/geo";
const olo = "http://purl.org/ontology/olo";

export const API_CONTEXT = `${process.env.REACT_APP_HSG_BASE_URL}/api-context`;

export const prefixes = {
  HSG: `${hsg}/hsg`,
  NARRATION: `${hsg}/narration`,
  KNOWLEDGE: `klg`,
  SOURCE: `${hsg}/source`,
  POS: `${geo}/wgs84_pos#`,
  OLO: `${olo}/core#`
};

export const commonProperties = {
  ID: "o:id",
  TITLE: "o:title",
  DESCRIPTION: "o:description",
  TERM: "o:term",
  ITEM_SET: "o:item_set",
  IS_PUBLIC: "o:is_public",
  LABEL: "o:label",
  MEDIA: "o:media",
  MEDIA_TYPE: "o:media_type",
  SOURCE: "o:source",
  ORIGINAL_URL: "o:original_url",
  RESOURCE_CLASS: "o:resource_class",
  OWNER: "o:owner",
  COMMENT: "o:comment",
  VALUE_RESOURCE_REFERENCE: "value_resource_reference",
  VALUE_RESOURCE_ID: "value_resource_id",
  TYPE: "@type",
  VALUE: "@value",
  CONTEXT: "@context",
  HAS_KNOWLEDGE_ENCODING: "has_knowledge_encoding",
  HAS_SOURCE_REFERENCE: "has_source_reference-f",
  PROPERTY_ID: "property_id",
  PROPERTY_LABEL: "property_label",
  RESOURCE_TYPE: "type"
}


/*--------------------------------------------------
  NARRATION
--------------------------------------------------*/

export const paragraphs = {

  STYLE_ANALYSIS: {
    label: "styleAnalysis",
    class: `${prefixes.NARRATION}:Style_analysis-p`,
    property: `${prefixes.NARRATION}:has_style_analysis`,
    author: true,
  },
  AUTHOR: {
    label: "author",
    class: `${prefixes.NARRATION}:Author-p`,
    property: `${prefixes.NARRATION}:has_author-p`,
    author: true,
  },
  CIRCULATION: {
    label: "circulation",
    class: `${prefixes.NARRATION}:Circulation-p`,
    property: `${prefixes.NARRATION}:has_circulation-p`,
    author: true,
  },
  COMMISSIONING: {
    label: "commissioning",
    class: `${prefixes.NARRATION}:Commissioning-p`,
    property: `${prefixes.NARRATION}:has_commissioning-p`,
    author: true,
  },
  CONTEXT: {
    label: "context",
    class: `${prefixes.NARRATION}:Context-p`,
    property: `${prefixes.NARRATION}:has_context-p`,
    author: true,
  },
  CHRONOLOGY: {
    label: "chronology",
    class: `${prefixes.NARRATION}:Chronology-p`,
    property: `${prefixes.NARRATION}:has_chronology-p`,
    author: true,
  },
  OBJECT_DESCRIPTION: {
    label: "objectDescription",
    class: `${prefixes.NARRATION}:Object_description-p`,
    property: `${prefixes.NARRATION}:has_object_description-p`,
    author: true,
  },
  FUNCTION: {
    label: "function",
    class: `${prefixes.NARRATION}:Function-p`,
    property: `${prefixes.NARRATION}:has_function-p`,
    author: true,
  },
  LOCATION: {
    label: "location",
    class: `${prefixes.NARRATION}:Location-p`,
    property: `${prefixes.NARRATION}:has_location-p`,
    author: true,
  },

  OTHER: {
    label: "other",
    class: `${prefixes.NARRATION}:Article_paragraph`,
    property: `${prefixes.NARRATION}:has_paragraph`,
    author: true
  }
};

export const ArticleClass = {
  label: "article",
  class: `${prefixes.NARRATION}:Article`,
  properties: {
    dcTermsTitle: "dcterms:title",
    dcTermsDescription: "dcterms:description",
    title: `${prefixes.NARRATION}:has_title`,
    description: `${prefixes.NARRATION}:has_description`,
    narrator: `${prefixes.NARRATION}:has_narrator-f`,
    knowledgeEncoding: `${prefixes.HSG}:${commonProperties.HAS_KNOWLEDGE_ENCODING}`,
    //sourceReference: `${prefixes.NARRATION}:${commonProperties.HAS_SOURCE_REFERENCE}`,
    paragraphs: {
      ...paragraphs
    }
  }
};

export const NarratorClass = {
  label: "narrator",
  class: `${prefixes.NARRATION}:Narrator-f`,
  properties: {
    title: "dcterms:title",
    text: `${prefixes.NARRATION}:has_text`
  }
};

export const ParagraphClass = {
  label: "paragraph",
  class: `${prefixes.NARRATION}:Article_paragraph`,
  properties: {
    title: "dcterms:title",
    text: `${prefixes.NARRATION}:has_text`,
    pivot: `${prefixes.NARRATION}:pivot`,
    previous: `${prefixes.OLO}:previous`,
    next: `${prefixes.OLO}:next`,
    knowledgeEncoding: `${prefixes.HSG}:${commonProperties.HAS_KNOWLEDGE_ENCODING}`,
    sourceReference: `${prefixes.NARRATION}:${commonProperties.HAS_SOURCE_REFERENCE}`,
  }
};

export const SourceReferenceClass = {
  label: "source",
  class: `${prefixes.NARRATION}:Source_reference-f`,
  properties: {
    title: "dcterms:title",
    text: `${prefixes.NARRATION}:has_text`,
    references: `${prefixes.HSG}:references`,
  }
};


/*------------------------------------------------
  SOURCES
------------------------------------------------*/

export const sources = {
  ARTICLE: {
    label: "article",
    class: `${prefixes.SOURCE}:Article`
  },
  AUDIO_VISUAL_DOCUMENT: {
    label: "audioVisualDocument",
    class: `${prefixes.SOURCE}:AudiovisualDocument`
  },
  BOOK: {
    label: "book",
    class: `${prefixes.SOURCE}:Book`
  },
  BOOK_CHAPTER: {
    label: "bookChapter",
    class: `${prefixes.SOURCE}:Chapter`
  },
  COLLECTION: {
    label: "collection",
    class: `${prefixes.SOURCE}:Collection`
  },
  GENERIC_SOURCE: {
    label: "genericSource",
    class: `${prefixes.SOURCE}:Generic_source`
  },
  IMAGE: {
    label: "image",
    class: `${prefixes.SOURCE}:Image`
  },
  MAP: {
    label: "map",
    class: `${prefixes.SOURCE}:Map`
  },
  MANUSCRIPT: {
    label: "manuscript",
    class: `${prefixes.SOURCE}:Manuscript`
  },
  WEBPAGE: {
    label: "webpage",
    class: `${prefixes.SOURCE}:Webpage`
  }
};

export const supportedFileExtensions = {
  IMAGE: "data:image/",
  VIDEO: "data:video/",
  AUDIO: "data:audio/",
  DOCUMENT: "data:application/",
  TEXT: "data:text/",
}

export const SourceClass = {
  label: "",
  sources: {
    ...sources
  },
  properties: {
    title: "dcterms:title",
    url: `${prefixes.SOURCE}:has_url`,
    //text: `${prefixes.NARRATION}:has_text`,
  }
};

export const MediaClass = {
  label: "",
  properties: {
    title: "dcterms:title",
  }
};


/*------------------------------------------------
  KNOWLEDGE
------------------------------------------------*/

export const entities = {

  AGENT: {
    label: "agent",
    class: `${prefixes.KNOWLEDGE}:Agent`,
    //comment: "geographicRegionComment",
    author: false
  },
  COLLECTIVE_AGENT: {
    label: "collectiveAgent",
    class: `${prefixes.KNOWLEDGE}:Collective_agent`,
    //comment: "geographicRegionComment",
    author: true
  },
  SINGLE_AGENT: {
    label: "singleAgent",
    class: `${prefixes.KNOWLEDGE}:Single_agent`,
    //comment: "geographicRegionComment",
    author: true
  },
  AUTHORSHIP_ASSIGNMENT: {
    label: "authorshipAssignment",
    class: `${prefixes.KNOWLEDGE}:Authorship_assignment`,
    //comment: "geographicRegionComment",
    author: true
  },
  CONTEXTUAL_STATE_OF_AFFAIRS: {
    label: "contextualStateOfAffairs",
    class: `${prefixes.KNOWLEDGE}:contextual_state_of_affairs`,
    //comment: "geographicRegionComment",
    author: true
  },
  EVENT: {
    label: "event",
    class: `${prefixes.KNOWLEDGE}:Event`,
    //comment: "geographicRegionComment",
    author: true
  },
  TEMPORAL_INTERVAL: {
    label: "temporalInterval",
    class: `${prefixes.KNOWLEDGE}:Temporal_interval`,
    //comment: "geographicRegionComment",
    author: true
  },
  PLACE: {
    label: "place",
    class: `${prefixes.KNOWLEDGE}:Place`,
    //comment: "geographicRegionComment",
    author: true
  },
  /*REFERENCE_MODEL: {
    label: "referenceModel",
    class: `${prefixes.KNOWLEDGE}:Reference_model`,
    //comment: "geographicRegionComment",
    author: true
  },*/
  OBJECT: {
    label: "object",
    class: `${prefixes.KNOWLEDGE}:Object`,
    //comment: "geographicRegionComment",
    author: true
  },
  PHYSICAL_OBJECT: {
    label: "physicalObject",
    class: `${prefixes.KNOWLEDGE}:Physical_object`,
    //comment: "geographicRegionComment",
    author: true
  },
  /*SITUATION: {
    label: "situation",
    class: `${prefixes.KNOWLEDGE}:Situation`,
    //comment: "geographicRegionComment",
    author: true
  },*/
  THEME: {
    label: "theme",
    class: `${prefixes.KNOWLEDGE}:Theme`,
    //comment: "geographicRegionComment",
    author: true
  }

};

export const entitiesTree = [
  {
    name: entities.AGENT,
    children: [
      {
        name: entities.COLLECTIVE_AGENT,
        children: []
      },
      {
        name: entities.SINGLE_AGENT,
        children: []
      }
    ]
  },
  {
    name: entities.AUTHORSHIP_ASSIGNMENT,
    children: []
  },
  {
    name: entities.CONTEXTUAL_STATE_OF_AFFAIRS,
    children: []
  },
  {
    name: entities.EVENT,
    children: []
  },
  {
    name: entities.TEMPORAL_INTERVAL,
    children: []
  },
  {
    name: entities.PLACE,
    children: []
  },
  {
    name: entities.REFERENCE_MODEL,
    children: []
  },
  {
    name: entities.OBJECT,
    children: []
  },
  {
    name: entities.PHYSICAL_OBJECT,
    children: []
  },
  {
    name: entities.SITUATION,
    children: []
  },
  {
    name: entities.THEME,
    children: []
  }
];

export const KnowledgeClass = {
  label: "knowledge",
  //class: `${prefixes.NARRATION}:Source_reference-f`,
  properties: {
    title: `dcterms:title`,
    latLong: `${prefixes.POS}:lat_long`,
    entities: {
      ...entities
    }
  }
};

export const getTopLevelEntities = (name) => {
  const entityTree = entitiesTree.filter(el => {
    return el.class === name;
  });

  if (entityTree.length === 1) {
    return getLevelEntities([], entityTree[0]);
  } else {
    return [];
  }
};

const getLevelEntities = (items, entity) => {
  if (entity.children.length > 0) {
    for (const childEntity of entity.children) {
      return items.concat(getLevelEntities(childEntity));
    }
  } else {
    return items.concat(entity);
  }
};


export const relations = {

  HAS_AGENT: {
    label: "hasAgent",
    property: `${prefixes.KNOWLEDGE}:has_agent`,
    //comment: "hasAgentComment",
    from: [],
    to: [],
    author: true
  },
  HAS_AGENT_OF_AUTHORSHIP_ASSIGNMENT: {
    label: "hasAgentOfAuthorshipAssignment",
    property: `${prefixes.KNOWLEDGE}:has_agent_of_authorship_assignment`,
    //comment: "hasAgentComment",
    from: [],
    to: [],
    author: true,
    domain: [entities.AUTHORSHIP_ASSIGNMENT],
    range: [entities.AGENT, entities.COLLECTIVE_AGENT, entities.SINGLE_AGENT]
  },
  IS_AGENT_OF_AUTHORSHIP_ASSIGNMENT: {
    label: "isAgentOfAuthorshipAssignment",
    property: `${prefixes.KNOWLEDGE}:is_agent_of_authorship_assignment`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true,
    domain: [entities.AGENT, entities.COLLECTIVE_AGENT, entities.SINGLE_AGENT],
    range: [entities.AUTHORSHIP_ASSIGNMENT]
  },
  IS_AGENT_OF: {
    label: "isAgentOf",
    property: `${prefixes.KNOWLEDGE}:is_agent_of`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true
  },
  /*ABSTRACTS_FROM: {
    label: "abstractsFrom",
    property: `${prefixes.KNOWLEDGE}:abstracts_from`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true
  },
  ABSTRACTS_IN: {
    label: "abstractsIn",
    property: `${prefixes.KNOWLEDGE}:abstracts_in`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true
  },*/
  AUTHORSHIP_ATTRIBUTED_TO: {
    label: "authorshipAttributedTo",
    property: `${prefixes.KNOWLEDGE}:authorship_attributed_to`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true,
    domain: [entities.OBJECT, entities.PHYSICAL_OBJECT],
    range: [entities.AGENT, entities.COLLECTIVE_AGENT, entities.SINGLE_AGENT],
    shortcut: [
      {
        from: [entities.OBJECT, entities.PHYSICAL_OBJECT],
        to: [entities.AGENT, entities.COLLECTIVE_AGENT, entities.SINGLE_AGENT],
        createEntities: [
          {
            entity: entities.EVENT,
            relations: [
              {
                relation: "hasObjectOfAuthorshipAssignment",
                to: "domain"
              },
              {
                relation: "hasRecipientOfAuthorshipAssignment",
                to: "range"
              },
              {
                relation: "hasAgentOfAuthorshipAssignment",
                to: "new_Agent"
              }
            ]
          }
        ]
      }
    ]
  },
  ATTRIBUTED_AUTHORSHIP_OF: {
    label: "attributedAuthorshipOf",
    property: `${prefixes.KNOWLEDGE}:attributed_authorship_of`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true,
    domain: [entities.AGENT, entities.COLLECTIVE_AGENT, entities.SINGLE_AGENT],
    range: [entities.OBJECT, entities.PHYSICAL_OBJECT],
    shortcut: [
      {
        from: [entities.AGENT, entities.COLLECTIVE_AGENT, entities.SINGLE_AGENT],
        to: [entities.OBJECT, entities.PHYSICAL_OBJECT],
        createEntities: [
          {
            entity: entities.EVENT,
            relations: [
              {
                relation: "hasObjectOfAuthorshipAssignment",
                to: "range"
              },
              {
                relation: "hasRecipientOfAuthorshipAssignment",
                to: "domain"
              },
              {
                relation: "hasAgentOfAuthorshipAssignment",
                to: "new_Agent"
              }
            ]
          }
        ]
      }
    ]
  },
  AUTHORSHIP_ATTRIBUTED_IN_TIME_SPAN: {
    label: "authorshipAttributedInTimeSpan",
    property: `${prefixes.KNOWLEDGE}:authorship_attributed_in_time-span`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true
  },
  IS_AUTHORSHIP_ATTRIBUTION_TIME_SPAN_OF: {
    label: "isAuthorshipAttributionTimeSpanOf",
    property: `${prefixes.KNOWLEDGE}:is_authorship_attribution_time-span_of`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true
  },
  IS_PRODUCTION_TIME_SPAN_OF: {
    label: "isProductionTimeSpanOf",
    property: `${prefixes.KNOWLEDGE}:is_production_time-span_of`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true,
    domain: [entities.TEMPORAL_INTERVAL],
    range: [entities.OBJECT, entities.PHYSICAL_OBJECT]
  },
  CAUSES: {
    label: "causes",
    property: `${prefixes.KNOWLEDGE}:causes`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true,
    domain: [entities.EVENT, entities.CONTEXTUAL_STATE_OF_AFFAIRS],
    range: [entities.EVENT, entities.CONTEXTUAL_STATE_OF_AFFAIRS]
  },
  WAS_MOTIVATED_OR_CAUSED_BY: {
    label: "wasMotivatedOrCausedBy",
    property: `${prefixes.KNOWLEDGE}:was_motivated_or_caused_by`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true,
    domain: [entities.EVENT, entities.CONTEXTUAL_STATE_OF_AFFAIRS],
    range: [entities.EVENT, entities.CONTEXTUAL_STATE_OF_AFFAIRS]
  },
  /*CAUSED_BY: {
    label: "causedBy",
    property: `${prefixes.KNOWLEDGE}:caused_by`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true
  },*/
  HAS_CURRENT_PERMANENT_LOCATION: {
    label: "hasCurrentPermanentLocation",
    property: `${prefixes.KNOWLEDGE}:has_current_permanent_location`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true
  },
  IS_CURRENT_PERMANENT_LOCATION_OF: {
    label: "isCurrentPermanentLocationOf",
    property: `${prefixes.KNOWLEDGE}:is_current_permanent_location_of`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true
  },
  CONTAINS: {
    label: "contains",
    property: `${prefixes.KNOWLEDGE}:contains`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true,
    domain: [entities.PLACE],
    range: [entities.PLACE]
  },
  HAS_RECIPIENT: {
    label: "hasRecipient",
    property: `${prefixes.KNOWLEDGE}:has_recipient`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true
  },
  HAS_RECIPIENT_OF_AUTHORSHIP_ASSIGNMENT: {
    label: "hasRecipientOfAuthorshipAssignment",
    property: `${prefixes.KNOWLEDGE}:has_recipient_of_authorship_assignment`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true,
    domain: [entities.AUTHORSHIP_ASSIGNMENT],
    range: [entities.AGENT, entities.COLLECTIVE_AGENT, entities.SINGLE_AGENT]
  },
  IS_RECIPIENT_OF: {
    label: "isRecipientOf",
    property: `${prefixes.KNOWLEDGE}:is_recipient_of`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true
  },
  HAS_CURRENT_LOCATION: {
    label: "hasCurrentLocation",
    property: `${prefixes.KNOWLEDGE}:has_current_location`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true
  },
  IS_CURRENT_LOCATION_OF: {
    label: "isCurrentLocationOf",
    property: `${prefixes.KNOWLEDGE}:is_current_location_of`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true
  },
  IS_COMPOSED_OF: {
    label: "isComposedOf",
    property: `${prefixes.KNOWLEDGE}:is_composed_of`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true,
    domain: [entities.OBJECT, entities.PHYSICAL_OBJECT],
    range: [entities.OBJECT, entities.PHYSICAL_OBJECT]
  },
  IS_CONTEXT_OF: {
    label: "isContextOf",
    property: `${prefixes.KNOWLEDGE}:is_context_of`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true,
    domain: [entities.CONTEXTUAL_STATE_OF_AFFAIRS],
    range: [entities.EVENT, entities.CONTEXTUAL_STATE_OF_AFFAIRS]
  },
  IS_RECIPIENT_OF_AUTHORSHIP_ASSIGNED: {
    label: "isRecipientOfAuthorshipAssigned",
    property: `${prefixes.KNOWLEDGE}:is_recipient_of_authorship_assigned`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true,
    domain: [entities.AGENT, entities.COLLECTIVE_AGENT, entities.SINGLE_AGENT],
    range: [entities.AUTHORSHIP_ASSIGNMENT]
  },
  IS_FORMER_OR_CURRENT_LOCATION_OF: {
    label: "isFormerOrCurrentLocationOf",
    property: `${prefixes.KNOWLEDGE}:is_former_or_current_location_of`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true,
    domain: [entities.PLACE],
    range: [entities.PHYSICAL_OBJECT]
  },
  HAS_FORMER_OR_CURRENT_LOCATION: {
    label: "hasFormerOrCurrentLocation",
    property: `${prefixes.KNOWLEDGE}:has_former_or_current_location`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true,
    domain: [entities.PHYSICAL_OBJECT],
    range: [entities.PLACE]
  },
  IS_RELATING_TO: {
    label: "isRelatingTo",
    property: `${prefixes.KNOWLEDGE}:is_relating_to`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true
  },
  HAS_TIME_SPAN: {
    label: "hasTimeSpan",
    property: `${prefixes.KNOWLEDGE}:has_time-span`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true,
    domain: [entities.EVENT, entities.CONTEXTUAL_STATE_OF_AFFAIRS],
    range: [entities.TEMPORAL_INTERVAL]
  },
  IS_TIME_SPAN_OF: {
    label: "isTimeSpanOf",
    property: `${prefixes.KNOWLEDGE}:is_time-span_of`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true
  },
  HAS_MEMBER: {
    label: "hasMember",
    property: `${prefixes.KNOWLEDGE}:has_member`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true
  },
  HAS_CURRENT_OR_FORMER_RESIDENCE: {
    label: "hasCurrentOrFormerResidence",
    property: `${prefixes.KNOWLEDGE}:has_current_or_former_residence`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true,
    domain: [entities.AGENT, entities.COLLECTIVE_AGENT, entities.SINGLE_AGENT],
    range: [entities.PLACE]
  },
  IS_MEMBER_OF: {
    label: "isMemberOf",
    property: `${prefixes.KNOWLEDGE}:is_member_of`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true
  },
  HAS_REFERENCE_MODEL: {
    label: "hasReferenceModel",
    property: `${prefixes.KNOWLEDGE}:has_reference_model`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true
  },
  IS_REFERENCE_MODEL_OF: {
    label: "isReferenceModelOf",
    property: `${prefixes.KNOWLEDGE}:is_reference_model_of`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true
  },
  FALLS_WITHIN: {
    label: "fallsWithin",
    property: `${prefixes.KNOWLEDGE}:falls_within`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true,
    domain: [entities.PLACE],
    range: [entities.PLACE]
  },
  HAS_OBJECT: {
    label: "hasObject",
    property: `${prefixes.KNOWLEDGE}:has_object`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true
  },
  HAS_OBJECT_OF_AUTHORSHIP_ASSIGNMENT: {
    label: "hasObjectOfAuthorshipAssignment",
    property: `${prefixes.KNOWLEDGE}:has_object_of_authorship_assignment`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true,
    domain: [entities.AUTHORSHIP_ASSIGNMENT],
    range: [entities.OBJECT, entities.PHYSICAL_OBJECT]
  },
  IS_OBJECT_OF: {
    label: "isObjectOf",
    property: `${prefixes.KNOWLEDGE}:is_object_of`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true
  },
  HAD_PARTICIPANT: {
    label: "hadParticipant",
    property: `${prefixes.KNOWLEDGE}:had_participant`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true,
    domain: [entities.EVENT],
    range: [entities.AGENT, entities.COLLECTIVE_AGENT, entities.SINGLE_AGENT]
  },
  PARTICIPATING_IN: {
    label: "participatingIn",
    property: `${prefixes.KNOWLEDGE}:participating_in`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true,
    domain: [entities.AGENT, entities.COLLECTIVE_AGENT, entities.SINGLE_AGENT],
    range: [entities.EVENT]
  },
  IS_CURRENT_OR_FORMER_RESIDENCE_OF: {
    label: "isCurrentOrFormerResidenceOf",
    property: `${prefixes.KNOWLEDGE}:is_current_or_former_residence_of`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true,
    domain: [entities.PLACE],
    range: [entities.AGENT, entities.COLLECTIVE_AGENT, entities.SINGLE_AGENT]
  },
  IS_OBJECT_OF_AUTHORSHIP_ASSIGNMENT: {
    label: "isObjectOfAuthorshipAssignment",
    property: `${prefixes.KNOWLEDGE}:is_object_of_authorship_assignment`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true,
    domain: [entities.OBJECT, entities.PHYSICAL_OBJECT],
    range: [entities.AUTHORSHIP_ASSIGNMENT]
  },
  IS_PART_OF: {
    label: "isPartOf",
    property: `${prefixes.KNOWLEDGE}:is_part_of`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true,
    domain: [entities.OBJECT, entities.PHYSICAL_OBJECT],
    range: [entities.OBJECT, entities.PHYSICAL_OBJECT]
  },
  HAS_PRODUCED: {
    label: "hasProduced",
    property: `${prefixes.KNOWLEDGE}:has_produced`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true,
    range: [entities.OBJECT, entities.PHYSICAL_OBJECT]
  },
  IS_PRODUCED_BY: {
    label: "isProducedBy",
    property: `${prefixes.KNOWLEDGE}:is_produced_by`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true
  },
  PRODUCED_BY: {
    label: "producedBy",
    property: `${prefixes.KNOWLEDGE}:produced_by`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true,
    domain: [entities.OBJECT, entities.PHYSICAL_OBJECT],
    range: [entities.AGENT, entities.COLLECTIVE_AGENT, entities.SINGLE_AGENT]
  },
  PRODUCED_IN_TIME_SPAN: {
    label: "producedInTimeSpan",
    property: `${prefixes.KNOWLEDGE}:produced_in_time-span`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true,
    domain: [entities.OBJECT, entities.PHYSICAL_OBJECT],
    range: [entities.TEMPORAL_INTERVAL]
  },
  PRODUCTION_OF: {
    label: "productionOf",
    property: `${prefixes.KNOWLEDGE}:production_of`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true,
    domain: [entities.AGENT, entities.COLLECTIVE_AGENT, entities.SINGLE_AGENT],
    range: [entities.OBJECT, entities.PHYSICAL_OBJECT]
  },
  HAS_FORMER_LOCATION: {
    label: "hasFormerLocation",
    property: `${prefixes.KNOWLEDGE}:has_former_location`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true
  },
  IS_FORMER_LOCATION_OF: {
    label: "isFormerLocationOf",
    property: `${prefixes.KNOWLEDGE}:is_former_location_of`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true
  },
  TOOK_PLACE_AT: {
    label: "tookPlaceAt",
    property: `${prefixes.KNOWLEDGE}:took_place_at`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true,
    domain: [entities.EVENT, entities.CONTEXTUAL_STATE_OF_AFFAIRS],
    range: [entities.PLACE]
  },
  HAS_CONTEXT: {
    label: "hasContext",
    property: `${prefixes.KNOWLEDGE}:has_context`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true,
    domain: [entities.EVENT, entities.CONTEXTUAL_STATE_OF_AFFAIRS],
    range: [entities.CONTEXTUAL_STATE_OF_AFFAIRS]
  },
  HAS_THEME: {
    label: "hasTheme",
    property: `${prefixes.KNOWLEDGE}:has_theme`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true
  },
  IS_THEME_OF: {
    label: "isThemeOf",
    property: `${prefixes.KNOWLEDGE}:is_symbolic_theme_of`,
    //comment: "isAgentOfComment",
    from: [],
    to: [],
    author: true
  }
};
import React, { useEffect, useImperativeHandle, forwardRef, useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';

import classes from './SourcesMap.module.css';
import MARKER from '../../assets/images/markerIcon.svg';
import INACTIVE_MARKER from '../../assets/images/markerIconInactive.svg';

const SourcesMap = forwardRef((props, ref) => {
    const {
        narrations,
        knowledge,
        media,
    } = props;

    const [map, setMap] = useState(null);

    useImperativeHandle(ref, () => ({
        centerMap(latLngBounds) {
            if (map) {
                map.setView(latLngBounds.getCenter());
            }
        },
        getMapInstance() {
            return map;
        }
    }));

    useEffect(() => {
        if (!map) {
            const mapInstance = L.map('leaflet-map', { zoomControl: false }).setView([44.20279874695161, 7.575759887695313], 13);
            L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
                attribution: '&copy; OpenStreetMap contributors &copy; CARTO',
                subdomains: 'abcd',
                maxZoom: 20
            }).addTo(mapInstance);
            setMap(mapInstance);
        }
    }, [map]);

    useEffect(() => {
        if (map) {
            const offset = 0.0001; // Base offset to slightly change coordinates

            narrations.forEach((narration, narrIndex) => {
                narration.locations.forEach((location, locIndex) => {
                    const lat = parseFloat(location.lat) + offset * narrIndex;
                    const lng = parseFloat(location.lng) + offset * locIndex;

                    const popupContent = narration.fragments.reduce((content, fragment) => {
                        fragment.sourceReferences.forEach(sourceRef => {
                            if (sourceRef.sources && sourceRef.sources.length > 0) {
                                sourceRef.sources.forEach(source => {
                                    if (source.media && source.media.length > 0) {
                                        source.media.forEach(mediaItem => {
                                            content += `<div><strong>${mediaItem["o:title"]}</strong><br/><img src="${mediaItem.thumbnail_display_urls.medium}" alt="${mediaItem["o:title"]}" style="width: 100px; height: auto;" /><br/></div>`;
                                        });
                                    }
                                });
                            }
                        });
                        return content;
                    }, '');

                    if (popupContent) {
                    const marker = L.marker([lat, lng], { icon: L.icon({ iconUrl: INACTIVE_MARKER, iconSize: [20, 20], iconAnchor: [12, 41], popupAnchor: [1, -34] }) })
                        .addTo(map);
                        marker.bindPopup(popupContent);
                    }
                });
            });
        }
    }, [map, narrations]);

    return (
        <div>
            <div id="leaflet-map" className={classes.LeafletMap}></div>
        </div>
    );
});

export default SourcesMap;
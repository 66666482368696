import React, { useState } from "react";
import { Canvas, Node, Edge, MarkerArrow, Label, Icon } from "reaflow";
import classes from "./EntityNetwork.module.css";
import { useTranslation } from "react-i18next";

const EntityNetwork = (props) => {
    const { selectedEntity, knowledge, narrations, onClose } = props;
    const { t } = useTranslation();

    const [hoveredImage, setHoveredImage] = useState(null); // Stato per l'immagine ingrandita
    const [hoveredNodeText, setHoveredNodeText] = useState(null); // Stato per il testo completo del nodo

    const findRelatedEntities = (selectedEntity, knowledge) => {
        const relatedEntities = [];
        knowledge.forEach(entity => {
            entity.knowledgeRelations.forEach(rel => {
                if (rel.id === parseInt(selectedEntity, 10)) {
                    relatedEntities.push({ ...entity, rel: rel.rel });
                }
            });
            if (entity.id === parseInt(selectedEntity, 10)) {
                entity.knowledgeRelations.forEach(rel => {
                    const relatedEntity = knowledge.find(e => e.id === rel.id);
                    if (relatedEntity) {
                        relatedEntities.push({ ...relatedEntity, rel: rel.rel });
                    }
                });
            }
        });
        return relatedEntities;
    };

    const findRelatedNarrations = (selectedEntity, narrations) => {
        const relatedNarrations = [];
        narrations.forEach(narration => {
            narration.fragments.forEach(fragment => {
                if (fragment.knowledgeEncoding?.includes(parseInt(selectedEntity, 10))) {
                    relatedNarrations.push(narration);
                }
            });
        });
        return relatedNarrations;
    };

    const selectedEntityData = knowledge.find(entity => entity.id === parseInt(selectedEntity, 10));
    const relatedEntities = findRelatedEntities(selectedEntity, knowledge);
    const relatedNarrations = findRelatedNarrations(selectedEntity, narrations);

    const nodes = [];
    const edges = [];

    // Add narrations to nodes
    relatedNarrations.forEach(narration => {
        nodes.push({ id: `narration-${narration.id}`, text: narration.text, type: 'narration', width: 260, category: t("general.narration") });
        edges.push({
            id: `narration-${narration.id}-entity-${selectedEntity}`,
            from: `narration-${narration.id}`,
            to: `entity-${selectedEntity}`
        });
        narration.fragments.forEach(fragment => {
            fragment.sourceReferences && fragment.sourceReferences.forEach(sourceRef => {
                sourceRef.sources && sourceRef.sources
                    .filter(source => source.media && source.media.length > 0)
                    .forEach(source => {
                        source.media.forEach(media => {
                            nodes.push({
                                id: `media-${media['@id']}`,
                                text: media['o:title'],
                                type: 'media',
                                width: 370,
                                data: {
                                    type: 'media',
                                },
                                icon: {
                                    url: media.thumbnail_display_urls.square,
                                    height: 40,
                                    width: 40
                                },
                                fullImage: media.thumbnail_display_urls.large // URL dell'immagine grande
                            });
                            edges.push({
                                id: `narration-${narration.id}-media-${media['@id']}`,
                                from: `narration-${narration.id}`,
                                to: `media-${media['@id']}`
                            });
                        });
                    });
            });
        });
    });

    // Add entities to nodes
    relatedEntities.forEach(entity => {
        nodes.push({ id: `entity-${entity.id}`, text: entity.text, type: 'entity', width: 260, category: t("general.entities." + entity.label) });
        edges.push({
            id: `entity-${selectedEntity}-entity-${entity.id}`,
            from: `entity-${selectedEntity}`,
            to: `entity-${entity.id}`
        });
    });

    // Add selected entity to nodes
    if (selectedEntityData) {
        nodes.push({ id: `entity-${selectedEntity}`, text: selectedEntityData.text, type: 'selectedEntity', width: 260, category: t("general.entities." + selectedEntityData.label) });
    }

    return (
        <>
        <div className={classes.popupContainer}>
            <button className={classes.closeButton} onClick={onClose}>x</button>
            <Canvas
                fit={true}
                nodes={nodes}
                edges={edges}
                arrow={<MarkerArrow />}
                direction="RIGHT"
                node={(node) => (
                    <Node
                        {...node}
                        style={{
                            stroke: '#1a192b',
                            fill: node.properties.type === "selectedEntity" ? '#00FF00' : '#ffffff',
                            color: '#000000',
                        }}
                        label={<Label style={{
                            fill: 'black',
                            padding: '10px',
                        }} />}
                        icon={<Icon
                            style={{
                                padding: '10px',
                            }}
                        />}
                        nodePadding={[20, 20, 20, 20]}
                        onClick={() => {
                            if (node.properties.type === 'media' && node.properties.fullImage) {
                                setHoveredImage(node.properties.fullImage);
                            } else {
                                setHoveredNodeText(node.properties.category+": "+ node.properties.text);
                            }
                        }}
                    >
                    </Node>
                )}
                edge={(edge) => (
                    <Edge
                        {...edge}
                        style={{ stroke: '#c8c6c6' }}
                    />
                )}
            />
            {hoveredNodeText && (
                <div className={classes.textPopup} onClick={() => setHoveredNodeText(null)}>
                    <p>{hoveredNodeText}</p>
                </div>
            )}
        </div>
            {hoveredImage && (
                <div className={classes.imagePopup} onClick={() => setHoveredImage(null)}>
                    <img src={hoveredImage} alt="Preview" />
                </div>
            )}
        </>
    );
};

export default EntityNetwork;
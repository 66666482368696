import React from "react";
import * as GLOBAL_STYLES from '../../utils/stylesConstants';
import classes from './BottomNavigation.module.css';
import { useTranslation } from "react-i18next";
import {primaryColorLight} from "../../utils/stylesConstants";

const margin = ".5rem";

const icons = {
  'narrations-map': require('../../assets/images/map.svg').default,
  'entities-map': require('../../assets/images/map.svg').default,
  'sources-map': require('../../assets/images/map.svg').default,
  'narrations-network': require('../../assets/images/network.svg').default,
  'entities-network': require('../../assets/images/network.svg').default,
  'sources-network': require('../../assets/images/network.svg').default,
  'narrations': require('../../assets/images/narrationsActive.svg').default,
  'entities': require('../../assets/images/narrationsActive.svg').default,
  'sources': require('../../assets/images/narrationsActive.svg').default,
};

const BottomNavigation = (props) => {
  const { activeCode, buttons, style, change, fromNarration } = props;
  const { t } = useTranslation();
  const [hoveredButton, setHoveredButton] = React.useState(null);
  const [activeButton, setActiveButton] = React.useState(activeCode+ (fromNarration ? '' : '-map'));

  const gridTemplateColumns = () => {
    return buttons.map(() => '1fr').join(" ");
  }

  const findButtonIndex = (code) => {
    for (const [index, btn] of buttons.entries()) {
      if (btn.code === code) {
        return index;
      }
    }
    return -1;
  }

  const setActiveBtnPosition = () => {
    const index = findButtonIndex(activeCode);
    const fractPart = 100 / (buttons.length * 2);
    return (index * 2 * fractPart + fractPart).toFixed(2) + '%';
  }

  const setActiveBtnWidth = () => {
    const fractPart = 100 / (buttons.length);
    return `calc(${fractPart.toFixed(2)}% - ${margin})`;
  }

  return (
    <div style={{ ...style }}>
      <div className={classes.BtnContainer} style={{ gridTemplateColumns: gridTemplateColumns() }}>
        <div className={classes.ActiveBtn} style={{ left: setActiveBtnPosition(), width: setActiveBtnWidth(), backgroundColor: GLOBAL_STYLES.primaryColor }}></div>
        {buttons.map(btn => (
          <div
            key={btn.code}
            className={classes.ButtonWrapper}
            onMouseEnter={() => setHoveredButton(btn.code)}
            onMouseLeave={() => setHoveredButton(null)}
          >
            <p
              className={classes.Button}
              style={{ color: activeCode === btn.code ? '#fff' : GLOBAL_STYLES.textPrimaryColor }}
              onClick={() =>{
                if (fromNarration) {
                    change(btn.code);
                } else {
                  change(`${btn.code}-map`);
                  setActiveButton(`${btn.code}-map`);
                }
              }}
            >
              {btn.label}
            </p>
            {hoveredButton === btn.code && !fromNarration && (
              <div className={classes.AdditionalButtons}>
                <div
                  className={classes.AdditionalButton}
                  style={{ backgroundColor: activeButton === `${btn.code}-map` ? GLOBAL_STYLES.primaryColorLight : 'transparent' }}
                  onClick={() => {
                      setActiveButton(`${btn.code}-map`);
                      change(`${btn.code}-map`);
                }}
                >
                  <img src={icons[`${btn.code}-map`]} alt={`${btn.label} Map`} className={classes.Icon} />
                  <span className={classes.ButtonLabel}>{t("general.map")}</span>
                </div>
                <div
                  className={classes.AdditionalButton}
                  style={{ backgroundColor: activeButton === `${btn.code}-network` ? GLOBAL_STYLES.primaryColorLight : 'transparent' }}
                  onClick={() => {
                    setActiveButton(`${btn.code}-network`);
                    change(`${btn.code}-network`);
                  }}
                >
                  <img src={icons[`${btn.code}-network`]} alt={`${btn.label} Network`} className={classes.Icon} />
                  <span className={classes.ButtonLabel}>{t("general.network")}</span>
                </div>
                <div
                  className={classes.AdditionalButton}
                  style={{ backgroundColor: activeButton === btn.code ? GLOBAL_STYLES.primaryColorLight : 'transparent' }}
                  onClick={() => {
                    setActiveButton(btn.code);
                    change(btn.code);
                  }}
                >
                  <img src={icons[btn.code]} alt={`${btn.label} Active`} className={classes.Icon} />
                  <span className={classes.ButtonLabel}>{t("general.list")}</span>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BottomNavigation;
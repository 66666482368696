import React from "react";

import * as GLOBAL_STYLES from '../../utils/stylesConstants';

import USER_ICON from '../../assets/images/userAvatar.svg';
import HAMBURGER_ICON from '../../assets/images/hamburger.svg';

import classes from './TopBar.module.css';
import AuthorSaveAndOrExitButtons from "../AuthorSaveAndOrExitButtons/AuthorSaveAndOrExitButtons";

import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import { useTranslation } from "react-i18next";
import { roles, VIZ_TYPES, SEARCH_TYPES } from "../../utils/constants";

import DELETE from '../../assets/images/closeGrey.svg';
import DELETE_HOVERED from '../../assets/images/closePrimary.svg';
import SEARCH from '../../assets/images/searchIcon.svg';
import SEARCH_HOVERED from '../../assets/images/searchIconPrimary.svg';
import INFO_ICON from '../../assets/images/infoIcon.svg';
import FILTER_ICON from '../../assets/images/filterFilteringIcon.svg';
import FILTER_OUTLINE_ICON from '../../assets/images/filterOutlineIcon.svg';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const axios = require('axios').default;

const allowedSearchTypes = [
  SEARCH_TYPES.NARRATION,
  SEARCH_TYPES.NARRATOR,
  SEARCH_TYPES.FRAGMENT,
  SEARCH_TYPES.KNOWLEDGE,
  SEARCH_TYPES.SOURCE,
  SEARCH_TYPES.SOURCE_REFERENCE
];

const TopBar = React.forwardRef((props, ref) => {
  
  const {
    style,
    isAuthor,
    isNarrationDetail,
    isEdit,
    onDelete,
    onSaveAndQuit,
    onSave,
    onExit,
    onSearch,
    onParamsSearch,
    onClearSearch,
    onSetSearchTypes,
    currentSearchTypes,
    onFilterNarrations,
    narrationsFiltered,
    currentVizType,
    latLngFromMapClick,
    knowledge,
    onDistanceChange
  } = props;

  const inputRef = React.useRef();

  const { userData, userLogged, setLoginData } = React.useContext(UserContext);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [searchIconHovered, setSearchIconHovered] = React.useState(false);
  const [deleteIconHovered, setDeleteIconHovered] = React.useState(false);
  const [searchInfoOpen, setSearchInfoOpen] = React.useState(false);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [textEdited, setTextEdited] = React.useState(false);
  const [text, setText] = React.useState("");
  const [latitudine, setLatitudine] = React.useState("");
  const [longitudine, setLongitudine] = React.useState("");
  const [distance, setDistance] = React.useState("");
  const [showCoordinates, setShowCoordinates] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(false);

  const [luogo, setLuogo] = React.useState("");
  const [agente, setAgente] = React.useState("");
  const [oggetto, setOggetto] = React.useState("");
  const [situazione, setSituazione] = React.useState("");
  const [evento, setEvento] = React.useState("");
  const [tema, setTema] = React.useState("");
  const [modelloDiRiferimento, setModelloDiRiferimento] = React.useState("");
  const [intervallo, setIntervallo] = React.useState("");
  const [relazione, setRelazione] = React.useState("");

  const [luoghi, setLuoghi] = React.useState([]);
  const [agenti, setAgenti] = React.useState([]);
  const [oggetti, setOggetti] = React.useState([]);
  const [situazioni, setSituazioni] = React.useState([]);
  const [eventi, setEventi] = React.useState([]);
  const [temi, setTemi] = React.useState([]);
  const [modelliDiRiferimento, setModelliDiRiferimento] = React.useState([]);
  const [intervalli, setIntervalli] = React.useState([]);
  const [relazioni, setRelazioni] = React.useState([]);


  React.useEffect(() => {
    if (showCoordinates) {
      //console.log("latLng", latLngFromMapClick);
      setLatitudine(latLngFromMapClick.lat);
      setLongitudine(latLngFromMapClick.lng);
    }
  }, [latLngFromMapClick, showCoordinates]);

  const getRoleString = React.useCallback((role) => {
    let roleString;
    switch(role){
      case 0:
        roleString = t("general.roles.administrator");
        break;
      case 1:
        roleString = t("general.roles.editor")
        break;
      case 2:
        roleString = t("general.roles.guest")
        break;
      default:
        roleString = t("general.roles.guest")
    }
    return roleString;
  }, [t]);

  const onClear = React.useCallback(() => {
    inputRef.current.value = "";
    onClearSearch();
  }, [inputRef, onClearSearch])

  const toggleMenu = React.useCallback(() => {
    setMenuOpen(!menuOpen);
  }, [menuOpen]);
  
  const redirectTo = React.useCallback((url, state = null) => {
    if(state){
      navigate(url, { state: { ...state } });
    }
    else{
      navigate(url);
    }
  }, [navigate]);

  const onLogout = React.useCallback(() => {
    axios.post("/api/logout").then(
      (res) => {
        //console.log("LOGGED OUT", res);
        setMenuOpen(false);
        setLoginData();
        if(isAuthor){
          redirectTo("/visualization/narrations");
        }
      },
      (error) => {
        console.log("ERROR", error);
      }
    );
  }, [setLoginData, isAuthor, redirectTo]);

  const doDelayedSearch = React.useCallback(() => {

    const searchParams = {
        text,
        luogo,
        agente,
        oggetto,
        situazione,
        evento,
        tema,
        modelloDiRiferimento,
        intervallo,
        relazione,
        latitudine,
        longitudine,
        distance
    }
    onParamsSearch(searchParams);
    onSearch(text);
    setLoading(false);
    setTextEdited(false);
  }, [text, onSearch]);

  const selectDeselectAll = React.useCallback(() => {
    if(currentSearchTypes.length < allowedSearchTypes.length){
      const notYetAddedSearchTypes = allowedSearchTypes.filter(el => !currentSearchTypes.includes(el));
      onSetSearchTypes(notYetAddedSearchTypes, true);
    }
    else{
      onSetSearchTypes(allowedSearchTypes, false);
    }
  }, [currentSearchTypes, onSetSearchTypes]);

  const knowledgeTypes = [
    "agent",
    "collectiveAgent",
    "singleAgent",
    "event",
    "temporalInterval",
    "place",
    "referenceModel",
    "object",
    "situation",
    "theme",

  ];
  React.useEffect(() => {
    if (knowledge) {
      //console.log("KNOWLEDGE", knowledge);
      let newLuoghi = [];
      let newAgenti = [];
      let newOggetti = [];
      let newSituazioni = [];
      let newEventi = [];
      let newTemi = [];
      let newModelliDiRiferimento = [];
      let newIntervalli = [];
      let newRelazioni = new Set();
      const newRelazioniSet = new Set();

      knowledge.forEach(el => {
        if (el.label === "place" && !newLuoghi.some(item => item.text === el.text)) {
          newLuoghi.push(el);
        }
        if (el.label === "agent" && !newAgenti.some(item => item.text === el.text)) {
          newAgenti.push(el);
        }
        if (el.label === "collectiveAgent" && !newAgenti.some(item => item.text === el.text)) {
          newAgenti.push(el);
        }
        if (el.label === "singleAgent" && !newAgenti.some(item => item.text === el.text)) {
          newAgenti.push(el);
        }
        if (el.label === "object" && !newOggetti.some(item => item.text === el.text)) {
          newOggetti.push(el);
        }
        if (el.label === "situation" && !newSituazioni.some(item => item.text === el.text)) {
          newSituazioni.push(el);
        }
        if (el.label === "event" && !newEventi.some(item => item.text === el.text)) {
          newEventi.push(el);
        }
        if (el.label === "theme" && !newTemi.some(item => item.text === el.text)) {
          newTemi.push(el);
        }
        if (el.label === "referenceModel" && !newModelliDiRiferimento.some(item => item.text === el.text)) {
          newModelliDiRiferimento.push(el);
        }
        if (el.label === "temporalInterval" && !newIntervalli.some(item => item.text === el.text)) {
          newIntervalli.push(el);
        }


        if (el.knowledgeRelations) {
          el.knowledgeRelations.forEach(rel => {
            newRelazioniSet.add(JSON.stringify({ id: rel.rel, label: rel.rel }));
          });
        }
      });

      setLuoghi(newLuoghi);
      setAgenti(newAgenti);
      setOggetti(newOggetti);
      setSituazioni(newSituazioni);
      setEventi(newEventi);
      setTemi(newTemi);
      setModelliDiRiferimento(newModelliDiRiferimento);
      setIntervalli(newIntervalli);
      setRelazioni(Array.from(newRelazioniSet).map(el => JSON.parse(el)));
    }
  }, [knowledge]);

  React.useEffect(() => {
    //console.log("luoghi", luoghi);

  } , [relazioni]);

  return (
    <div ref={ref} style={{ ...style }} className={[classes.TopBar, isAuthor ? classes.AuthorTopBar : ( isNarrationDetail ? classes.NarrationDetailTopBar : classes.VisualizationTopBar)].join(" ")}>
      <p className={classes.Name} style={{ color: GLOBAL_STYLES.textSecondaryColor }}>HISTORYGRAPHIA</p>
      { 
        !isAuthor && !isNarrationDetail &&
        <div className={classes.InputContainer}>
          <input
            ref={inputRef}
            type="text"
            placeholder="Cerca"
            className={classes.Input}
            onChange={(e) => {
              setTextEdited(true);
              setText(e.target.value)
            }}
            onKeyUp={(k) => {
              setText(k.target.value);
              if(k.key === "Enter"){
                setLoading(true);
                doDelayedSearch();
              }
            }}
          />
          {
            !loading && !textEdited &&
            <img
              src={deleteIconHovered ? DELETE_HOVERED : DELETE}
              className={classes.DeleteSearch}
              onClick={() => onClear()}
              onMouseEnter={() => setDeleteIconHovered(true)}
              onMouseLeave={() => setDeleteIconHovered(false)}
              alt=""
            />
          }
          {
            !loading && textEdited &&
            <img
              src={searchIconHovered ? SEARCH_HOVERED : SEARCH}
              className={classes.DeleteSearch}
              onMouseEnter={() => setSearchIconHovered(true)}
              onMouseLeave={() => setSearchIconHovered(false)}
              onClick={() => {
                setLoading(true);
                doDelayedSearch()
              }}
              alt=""
            />
          }
          {
            loading &&
            <span className={classes.Loader}></span>
          }
          <img
            className={classes.InfoIcon}
            src={INFO_ICON}
            alt=""
            title={t("general.search.filterSearchText")}
            onClick={() => setSearchInfoOpen(!searchInfoOpen)}
          />
          {
            currentVizType === VIZ_TYPES.NETWORK &&
            <img
              className={classes.FilterIcon}
              src={narrationsFiltered ? FILTER_ICON : FILTER_OUTLINE_ICON}
              alt=""
              title={t("general.search.filterSearchText")}
              onClick={() => onFilterNarrations(!narrationsFiltered)}
            />
          }
          {
            searchInfoOpen &&
              <div className={classes.FilterSearchArea}>
                <div className={classes.SearchAreaIntestation}>
                  <p className={classes.SearchAreaTitle}>{t("general.search.title")}:</p>
                  <p className={classes.SelectDeselectAll}
                     onClick={() => selectDeselectAll()}>{currentSearchTypes.length < allowedSearchTypes.length ? t("general.search.selectAll") : t("general.search.deselectAll")}</p>
                </div>
                <div className={classes.SearchAreaContainer}>
                  {
                    allowedSearchTypes.map(el => {
                      return (
                          <div key={"filter-search-unique-key-" + el} className={classes.SearchChoiceContainer}
                               onClick={() => onSetSearchTypes([el], !currentSearchTypes.includes(el))}>
                            <input className={classes.SearchChoiceCheckBox} type="checkbox"
                                   checked={currentSearchTypes.includes(el)} readOnly/>
                            <p className={classes.SearchChoiceLabel}>{t("general." + el)}</p>
                          </div>
                      )
                    })
                  }
                </div>

                <div className={classes.FilterByLocationContainer}>
                  <p className={classes.SearchChoiceLabel}>{t("general.search.filterByEntityAndRelation")}</p>
                  <input
                      type="checkbox"
                      checked={showFilter}
                      onChange={(e) => setShowFilter(e.target.checked)}
                      className={classes.Checkbox}
                  />
                </div>

                {
                    showFilter && (
                    <>
                      <div className={classes.InputGroup}>
                        {luoghi.length > 0 ? (
                            <Autocomplete
                                options={luoghi}
                                getOptionLabel={(option) => option.text || ""}
                                renderInput={(params) => (
                                    <TextField {...params} label={t("general.search.luogo")} variant="outlined"
                                               className={classes.Input}/>
                                )}
                                value={luogo}
                                onChange={(event, newValue) => setLuogo(newValue)}
                                style={{width: '100%'}}
                            />
                        ) : (
                            <p>{t("general.noLocationsAvailable")}</p>
                        )}
                      </div>
                      <div className={classes.InputGroup}>
                        <Autocomplete
                            options={agenti}
                            getOptionLabel={(option) => option.text || ""}
                            renderInput={(params) => (
                                <TextField {...params} label={t("general.search.agente")} variant="outlined"
                                           className={classes.Input}/>
                            )}
                            value={agente}
                            onChange={(event, newValue) => setAgente(newValue)}
                            style={{width: '100%'}}
                        />
                      </div>
                      <div className={classes.InputGroup}>
                        <Autocomplete
                            options={oggetti}
                            getOptionLabel={(option) => option.text || ""}
                            renderInput={(params) => (
                                <TextField {...params} label={t("general.search.oggetto")} variant="outlined"
                                           className={classes.Input}/>
                            )}
                            value={oggetto}
                            onChange={(event, newValue) => setOggetto(newValue)}
                            style={{width: '100%'}}
                        />
                      </div>
                      <div className={classes.InputGroup}>
                        <Autocomplete
                            options={situazioni}
                            getOptionLabel={(option) => option.text || ""}
                            renderInput={(params) => (
                                <TextField {...params} label={t("general.search.situazione")} variant="outlined"
                                           className={classes.Input}/>
                            )}
                            value={situazione}
                            onChange={(event, newValue) => setSituazione(newValue)}
                            style={{width: '100%'}}
                        />
                      </div>
                      <div className={classes.InputGroup}>
                        <Autocomplete
                            options={eventi}
                            getOptionLabel={(option) => option.text || ""}
                            renderInput={(params) => (
                                <TextField {...params} label={t("general.search.evento")} variant="outlined"
                                           className={classes.Input}/>
                            )}
                            value={evento}
                            onChange={(event, newValue) => setEvento(newValue)}
                            style={{width: '100%'}}
                        />
                      </div>
                      <div className={classes.InputGroup}>
                        <Autocomplete
                            options={temi}
                            getOptionLabel={(option) => option.text || ""}
                            renderInput={(params) => (
                                <TextField {...params} label={t("general.search.tema")} variant="outlined"
                                           className={classes.Input}/>
                            )}
                            value={tema}
                            onChange={(event, newValue) => setTema(newValue)}
                            style={{width: '100%'}}
                        />
                      </div>
                      <div className={classes.InputGroup}>
                        <Autocomplete
                            options={modelliDiRiferimento}
                            getOptionLabel={(option) => option.text || ""}
                            renderInput={(params) => (
                                <TextField {...params} label={t("general.search.modelloDiRiferimento")} variant="outlined"
                                           className={classes.Input}/>
                            )}
                            value={modelloDiRiferimento}
                            onChange={(event, newValue) => setModelloDiRiferimento(newValue)}
                            style={{width: '100%'}}
                        />
                      </div>
                      <div className={classes.InputGroup}>
                        <Autocomplete
                            options={intervalli}
                            getOptionLabel={(option) => option.text || ""}
                            renderInput={(params) => (
                                <TextField {...params} label={t("general.search.intervallo")} variant="outlined"
                                           className={classes.Input}/>
                            )}
                            value={intervallo}
                            onChange={(event, newValue) => setIntervallo(newValue)}
                            style={{width: '100%'}}
                        />
                      </div>
                      < div className={classes.InputGroup}>
                        <Autocomplete
                            options={relazioni}
                            getOptionLabel={(option) => option.label || ""}
                            renderInput={(params) => (
                                <TextField {...params} label={t("general.search.relazione")} variant="outlined"
                                           className={classes.Input}/>
                            )}
                            value={relazione}
                            onChange={(event, newValue) => setRelazione(newValue)}
                            style={{width: '100%'}}
                        />
                      </div>
                    </>
                )}

                <div className={classes.FilterByLocationContainer}>
                  <p className={classes.SearchChoiceLabel}>{t("general.search.filterByLocation")}</p>
                  <input
                      type="checkbox"
                      checked={showCoordinates}
                      onChange={(e) => setShowCoordinates(e.target.checked)}
                      className={classes.Checkbox}
                  />
                </div>
                {showCoordinates && (
                    <>
                      <div className={classes.InputGroup}>
                        <label className={classes.InputLabel}>{t("general.search.latitude")}</label>
                        <input
                            type="number"
                            placeholder={t("general.search.latitude")}
                            value={latitudine}
                            onChange={(e) => setLatitudine(e.target.value)}
                            className={classes.Input}
                        />
                      </div>
                      <div className={classes.InputGroup}>
                        <label className={classes.InputLabel}>{t("general.search.longitude")}</label>
                        <input
                            type="number"
                            placeholder={t("general.search.longitude")}
                            value={longitudine}
                            onChange={(e) => setLongitudine(e.target.value)}
                            className={classes.Input}
                        />
                      </div>
                      <div className={classes.InputGroup}>
                        <label className={classes.InputLabel}>{t("general.search.radius")}</label>
                        <input
                            type="number"
                            placeholder={t("general.search.radius")}
                            value={distance}
                            onChange={(e) => {
                              setDistance(e.target.value);
                              onDistanceChange(e.target.value);
                            }}
                            className={classes.Input}
                        />
                      </div>
                    </>
                )}


              </div>

          }
        </div>
      }
      {
          !isAuthor &&
          <div onClick={() => toggleMenu()} className={classes.IconButton} style={{margin: 'auto 3rem auto auto'}}>
            <img className={classes.Icon} src={userLogged ? USER_ICON : HAMBURGER_ICON} alt=""/>
          </div>
      }
      {
          isAuthor &&
          <AuthorSaveAndOrExitButtons
              isEdit={isEdit}
              onDelete={onDelete}
              onSaveAndQuit={onSaveAndQuit}
              onExit={onExit}
                onSave={onSave}
            />
        }
        {
            isAuthor &&
            <div onClick={() => toggleMenu()} className={classes.IconButton} style={{margin: 'auto 3rem auto 0'}}>
                <img className={classes.Icon} src={userLogged ? USER_ICON : HAMBURGER_ICON} alt=""/>
            </div>
        }
        {
            menuOpen &&
            <div className={classes.Menu}>
                {
                    userLogged &&
                    <>
                        <div className={classes.InstestationRow}>
                            <p className={classes.Username}>{userData.user}</p>
                            <p className={classes.Role}>{getRoleString(userData.role)}</p>
                        </div>
                        <div className={classes.Divider}/>
                        {
                            userData && userData.role <= roles.ADMINISTRATOR &&
                            <p
                                className={classes.Action}
                                onClick={() => {
                                    redirectTo("/add-user")
                                }}
                            >{t(`general.routes.addUser`)}</p>
                        }
                        {
                            userData && userData.role <= roles.EDITOR &&
                            <p
                                className={classes.Action}
                                onClick={() => {
                                    redirectTo(isAuthor ? "/visualization/narrations" : "/author")
                                }}
                            >{t(`general.routes.${isAuthor ? "goToVisualization" : "goToAuthor"}`)}</p>
                        }
                        <p
                            className={classes.Action}
                            onClick={() => onLogout()}
                        >{t("general.routes.logout")}</p>
                    </>
                }
                {
                    !userLogged &&
                    <>
                        <p
                            className={classes.Login}
                            onClick={() => redirectTo("/login", {from: pathname})}
                        >{t("general.routes.login")}</p>
                    </>
                }
            </div>
        }
    </div>
  );

});

export default TopBar;
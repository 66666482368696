import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import classes from './NarrationBox.module.css';

const NarrationBox = (props) => {

  const {
    narration,
    fragmentsNodes,
    onFragmentHover,
    author,
    createDate,
    modifiedDate,
  } = props;

  const { t } = useTranslation();
  console.log("NarrationBox", narration, fragmentsNodes);

  const [isNarrationExpanded, setIsNarrationExpanded] = useState(true);
  const [isRelatedExpanded, setIsRelatedExpanded] = useState(false);

  // Group related narrations by their ID
  const groupedRelatedNarrations = narration.relatedNarrations.reduce((acc, related) => {
    if (!acc[related.id]) {
      acc[related.id] = {
        text: related.text,
        relatedKnowledge: []
      };
    }
    acc[related.id].relatedKnowledge.push(related.relatedKnowledge);
    return acc;
  }, {});

  return (
      <div className={classes.Wrapper}>
        <div className={classes.NarrationContainer}>
          <div className={classes.Menu}>
            <div className={classes.MenuItem} onClick={() => setIsNarrationExpanded(!isNarrationExpanded)}>
              <p className={classes.NarrationTitle}>{narration.text}
                <span className={isNarrationExpanded ? classes.ArrowDown : classes.ArrowRight}></span>
              </p>
          </div>
          {isNarrationExpanded && (
              <div className={classes.NarrationContent}>
                  <div className={classes.NarrationFragmentsContainer}>
                    {
                      fragmentsNodes.map((frag) => {
                        return (
                            <p
                                key={"narration-" + narration.id + "-fragment-" + frag.id}
                                className={classes.NarrationFragment}
                                onMouseEnter={() => onFragmentHover(frag.id)}
                                onMouseLeave={() => onFragmentHover()}
                            >
                              {frag.data.label}
                            </p>
                        )
                      })
                    }
                  </div>
                  <div className={classes.NarrationTextContainer}>
                    {
                      fragmentsNodes.map(el => {
                        return (
                            <p
                                key={"narration-" + narration.id + "-fragment-" + el.id}
                                className={classes.NarrationText}
                            >{el.data.txt}</p>
                        )
                      })
                    }
                    {author && (
                        <div className={classes.Author}>
                          <p>{t('general.narrator')}: {author}</p>
                        </div>
                    )}
                  </div>
                </div>
            )}
            <div className={classes.MenuItem} onClick={() => setIsRelatedExpanded(!isRelatedExpanded)}>
              <p className={classes.NarrationTitle}>
                {t('general.relatedNarration_plural')}
                <span className={isRelatedExpanded ? classes.ArrowDown : classes.ArrowRight}></span>
              </p>
            </div>
            {isRelatedExpanded && (
                <div className={classes.RelatedNarrationsContainer}>
                  {
                    Object.keys(groupedRelatedNarrations)
                        .filter(relatedId => parseInt(relatedId) !== narration.id)
                        .map((relatedId, index) => {
                          const related = groupedRelatedNarrations[relatedId];
                          return (
                              <div key={"related-narration-" + relatedId + "-" + index} className={classes.RelatedNarration}>
                                <p className={classes.RelatedNarrationTitle}>{related.text}</p>
                                <ul className={classes.RelatedEntitiesList}>
                                  {
                                    related.relatedKnowledge.flat().map((entity, entityIndex) => (
                                        <li key={"related-entity-" + entity.id + "-" + entityIndex} className={classes.RelatedEntity}>
                                          {entity.text}
                                        </li>
                                    ))
                                  }
                                </ul>
                              </div>
                          )
                        })
                  }
                </div>
            )}
            {createDate && (
                <div className={classes.Date}>
                  <p>{t('general.dataCreated')}: {createDate}</p>
                </div>
            )}
            {modifiedDate && (
                <div className={classes.Date}>
                  <p>{t('general.dataModified')}: {modifiedDate}</p>
                </div>
            )}
          </div>
        </div>
      </div>
  );
};

export default NarrationBox;
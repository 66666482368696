import React, {useState} from "react";
import classes from './EntitiesList.module.css';
import EntityNetwork from "../EntityNetwork/EntityNetwork";
import { useTranslation } from "react-i18next";

const EntitiesList = (props) => {
    const {
        knowledge,
        narrations,
    } = props;

    const [selectedLabels, setSelectedLabels] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedEntity, setSelectedEntity] = useState(null);
    const { t } = useTranslation();

    // Get unique labels
    const labels = [...new Set(knowledge.map(entity => entity.label))];

    // Toggle label selection
    const onToggleLabel = (label) => {
        setSelectedLabels(prevSelectedLabels =>
            prevSelectedLabels.includes(label)
                ? prevSelectedLabels.filter(l => l !== label)
                : [...prevSelectedLabels, label]
        );
    };

    // Group entities by label
    const groupedEntities = knowledge.reduce((acc, entity) => {
        const label = entity.label;
        if (!acc[label]) {
            acc[label] = [];
        }
        acc[label].push(entity);
        return acc;
    }, {});

    const FilterBar = ({ labels, selectedLabels, onToggleLabel }) => {
        return (
            <div className={classes.FilterBar}>
                {labels.map(label => (
                    <label key={label} className={classes.FilterLabel}>
                        <input
                            type="checkbox"
                            checked={selectedLabels.includes(label)}
                            onChange={() => onToggleLabel(label)}
                        />
                        {t("general.entities." + label)}
                    </label>
                ))}
            </div>
        );
    };

    return (
        <div>
            {showPopup && (
                <EntityNetwork
                    selectedEntity={selectedEntity}
                    knowledge={knowledge}
                    narrations={narrations}
                    onClose={() => setShowPopup(false)}
                />
            )}
            <FilterBar
                labels={labels}
                selectedLabels={selectedLabels}
                onToggleLabel={onToggleLabel}
            />
            <div className={classes.EntitiesList}>
                {Object.keys(groupedEntities)
                    .filter(label => selectedLabels.length === 0 || selectedLabels.includes(label))
                    .map(label => (
                        <div key={label} className={classes.EntityGroup}>
                            <h2 className={classes.EntityLabel}>{t("general.entities." + label)}</h2>
                            <div className={classes.EntityItems}>
                                {groupedEntities[label].map(entity => (
                                    <div
                                        key={entity['id']}
                                        className={classes.EntityItem}
                                        onClick={() => {
                                            setSelectedEntity(entity['id']);
                                            setShowPopup(true);
                                        }}
                                    >
                                        <p>{entity.text}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
}

export default EntitiesList;
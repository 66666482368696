import React, { useEffect, useRef, useState } from 'react';
import classes from './imageCarousel.module.css';

const ImageCarousel = ({ images, onImageSelect }) => {
    const galleryRef = useRef(null);
    const [maxHeight, setMaxHeight] = useState(0);

    useEffect(() => {
        const galleryItems = galleryRef.current.querySelectorAll(`.${classes.galleryItem}`);
        let maxHeight = 0;

        const imageLoadPromises = Array.from(galleryItems).map(item => {
            const img = item.querySelector('img');
            return new Promise(resolve => {
                img.onload = () => {
                    if (img.clientHeight > maxHeight) {
                        maxHeight = img.clientHeight;
                    }
                    resolve();
                };
            });
        });

        Promise.all(imageLoadPromises).then(() => {
            setMaxHeight(maxHeight);
        });
    }, [images]);

    useEffect(() => {
        if (maxHeight === 0) return;

        const galleryItems = galleryRef.current.querySelectorAll(`.${classes.galleryItem}`);
        const smallItems = [];
        galleryItems.forEach(item => {
            const img = item.querySelector('img');
            if (img.clientHeight < maxHeight / 2) {
                smallItems.push(item);
            } else {
                item.style.height = `${maxHeight}px`;
            }
        });

        for (let i = 0; i < smallItems.length; i += 2) {
            const container = document.createElement('div');
            container.style.height = `${maxHeight}px`;
            container.style.display = 'grid';
            container.style.gridTemplateRows = '1fr 1fr';
            container.style.gap = '10px';

            if (smallItems[i]) {
                container.appendChild(smallItems[i]);
            }
            if (smallItems[i + 1]) {
                container.appendChild(smallItems[i + 1]);
            }

            galleryRef.current.appendChild(container);
        }
    }, [maxHeight]);

    const handleImageClick = (id) => {
        const selectedImage = images.find(image => image.id === id);
        onImageSelect(selectedImage);
    }

    return (
        <div className={classes.gallery} ref={galleryRef}>
            {images.map((image, index) => (
                <div key={index} className={classes.galleryItem} onClick={() => handleImageClick(image.id)}>
                    <img src={image.url} alt="carouselImage" className={classes.image} />
                    <div className={classes.overlay}>
                        <div className={classes.text}>{image.title}</div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default ImageCarousel;
import {
    API_CONTEXT,
    paragraphs,
    ArticleClass,
    NarratorClass,
    ParagraphClass,
    SourceReferenceClass,
    entities,
    relations,
    sources,
    commonProperties,
    KnowledgeClass,
    SourceClass,
    MediaClass
} from "./constants";
import {v4} from "uuid";

export const HSG_ITEM_SET = process.env.REACT_APP_HSG_ITEM_SET ? process.env.REACT_APP_HSG_ITEM_SET : 167;

const generateUniqueUuid = (alreadyTakenUuids) => {
    let uuid = v4();
    while (alreadyTakenUuids.includes(uuid)) {
        uuid = v4();
    }
    return uuid;
}

export const getNarrationsArray = (data, media) => {

    const obj = [];
    const articlesGot = data.filter(el => {
        return el[commonProperties.TYPE].includes(ArticleClass.class)
    });
    console.log("articlesGot", articlesGot);
    for (const a of articlesGot) {
        //console.log(JSON.stringify(a));

        const owner = a[commonProperties.OWNER];

        const title = a[ArticleClass.properties.title];

        const description = a[ArticleClass.properties.dcTermsDescription];
        //console.log("description", description);
        const narrator = a[ArticleClass.properties.narrator];
        const narratorItem = narrator && narrator.length > 0 ? getItem(data, narrator[0][commonProperties.VALUE_RESOURCE_ID]) : null;
        const narratorText = narratorItem ? narratorItem[NarratorClass.properties.text] : null;

        const created = a["o:created"] ? a["o:created"]["@value"] : null;
        const modified = a["o:modified"] ? a["o:modified"]["@value"] : null;

        //console.log("created", created);
        //console.log("modified", modified);

        const narr = {
            owner: owner ? owner[commonProperties.ID] : null,
            id: a[commonProperties.ID],
            text: title && title.length === 1 ? title[0][commonProperties.VALUE] : "-",
            description: description && description.length === 1 ? description[0][commonProperties.VALUE] : "-",
            narrator: narratorText && narratorText.length > 0 ? narratorText[0][commonProperties.VALUE] : "-",
            locations: [],
            fragments: [],
            created: created,
            modified: modified
        };


        for (const p of Object.values(ArticleClass.properties.paragraphs)) {
            if (a[p.property]) {
                const parData = a[p.property];
                for (const pData of parData) {
                    const paragraphData = data.filter(el => {
                        return el[commonProperties.ID] === pData[commonProperties.VALUE_RESOURCE_ID]
                    });
                    if (paragraphData.length === 1) {
                        const paragraph = paragraphData[0];
                        const previous = paragraph[ParagraphClass.properties.previous];
                        const next = paragraph[ParagraphClass.properties.next];
                        const knowledgeEncoding = paragraph[ParagraphClass.properties.knowledgeEncoding];
                        const sourceRefs = paragraph[ParagraphClass.properties.sourceReference];
                        const text = paragraph[ParagraphClass.properties.text];

                        if (knowledgeEncoding && knowledgeEncoding.length > 0) {
                            for (const knEnc of knowledgeEncoding) {
                                const knowledgeItem = getItem(data, knEnc[commonProperties.VALUE_RESOURCE_ID]);
                                if (knowledgeItem && knowledgeItem[KnowledgeClass.properties.latLong]) {
                                    for (const latLngElement of knowledgeItem[KnowledgeClass.properties.latLong]) {
                                        const coordinates = latLngElement[commonProperties.VALUE].trim().split(",");
                                        narr.locations.push({
                                            entityId: knowledgeItem[commonProperties.ID],
                                            entity: (knowledgeItem[KnowledgeClass.properties.title] && knowledgeItem[KnowledgeClass.properties.title].length === 1) ? knowledgeItem[KnowledgeClass.properties.title][0][commonProperties.VALUE] : '-',
                                            lat: coordinates[0],
                                            lng: coordinates[1]
                                        });
                                    }
                                }
                            }
                        }

                        const sourceReferences = [];
                        if (sourceRefs && sourceRefs.length > 0) {
                            for (const sourceRef of sourceRefs) {
                                //console.log("sourceRef", sourceRef);
                                const sourceRefItem = getItem(data, sourceRef[commonProperties.VALUE_RESOURCE_ID]);
                                //console.log("sourceRefItem", sourceRefItem);
                                if (sourceRefItem && sourceRefItem[commonProperties.TYPE].includes(SourceReferenceClass.class)) {
                                    const hasText = sourceRefItem[SourceReferenceClass.properties.text];
                                    const references = sourceRefItem[SourceReferenceClass.properties.references];
                                    const txt = hasText && hasText.length === 1 ? hasText[0][commonProperties.VALUE] : null;

                                    const sourceObjects = [];
                                    if (references && references.length > 0) {
                                        for (const s of references) {
                                            //console.log("s", s);
                                            const ref = getItem(data, s[commonProperties.VALUE_RESOURCE_ID]);
                                            //console.log("ref", ref);
                                            if (ref) {
                                                const sourceTitle = ref[SourceClass.properties.title];
                                                const sourceType = ref[commonProperties.TYPE][1];
                                                //console.log("sourceType", sourceType);
                                                const sourceMedia = [];

                                                const refObj = {};
                                                refObj.title = sourceTitle && sourceTitle.length > 0 ? sourceTitle[0][commonProperties.VALUE] : "-";
                                                refObj.type = sourceType.split(':').pop();
                                                //console.log("refObj type", refObj.type);
                                                if (ref[commonProperties.MEDIA] && ref[commonProperties.MEDIA].length > 0) {
                                                    for (const m of ref[commonProperties.MEDIA]) {
                                                        const mediaObject = getItem(media, m[commonProperties.ID]);
                                                        const mediaObj = {...mediaObject};
                                                        if (mediaObject) {
                                                            sourceMedia.push(mediaObj);
                                                        }
                                                    }
                                                }
                                                refObj.media = [...sourceMedia];
                                                sourceObjects.push(refObj)
                                            }
                                        }
                                    }

                                    sourceReferences.push({
                                        id: sourceRefItem[commonProperties.ID],
                                        txt: txt,
                                        sources: sourceObjects.length > 0 ? sourceObjects : null
                                    })

                                }
                            }
                        }

                        const fragmentObj = {
                            id: paragraph[commonProperties.ID],
                            label: p.label,
                            text: text && text.length === 1 ? text[0][commonProperties.VALUE] : "-",
                            pivot: paragraph[ParagraphClass.properties.pivot],
                            previous: previous && previous.length === 1 ? previous[0][commonProperties.VALUE_RESOURCE_ID] : null,
                            next: next && next.length === 1 ? next[0][commonProperties.VALUE_RESOURCE_ID] : null,
                            knowledgeEncoding: knowledgeEncoding && knowledgeEncoding.length > 0 ? knowledgeEncoding.map(el => {
                                return el[commonProperties.VALUE_RESOURCE_ID]
                            }) : [],
                            sourceReferences: sourceReferences
                        };

                        narr.fragments.push(fragmentObj);

                    }
                }
            }
        }
        obj.push(narr);
    }

    return obj;
}

export const getNarrationData = (id, data, media, alreadyExistingEntities, alreadyExistingSources) => {
    const articleGot = data.filter(el => {
        return (el[commonProperties.TYPE].includes(ArticleClass.class) && el[commonProperties.ID] === id)
    });

    if (articleGot && articleGot.length === 1) {

        const knEncItemArray = [];
        const article = articleGot[0];

        const owner = article[commonProperties.OWNER];

        const title = article[ArticleClass.properties.title];

        const description = article[ArticleClass.properties.dcTermsDescription];

        const created = article["o:created"] ? article["o:created"]["@value"] : null;
        const modified = article["o:modified"] ? article["o:modified"]["@value"] : null;

        const narrator = article[ArticleClass.properties.narrator];
        const narratorId = narrator && narrator.length > 0 ? narrator[0][commonProperties.VALUE_RESOURCE_ID] : null;
        const narratorItem = narratorId ? getItem(data, narratorId) : null;
        const narratorText = narratorItem ? narratorItem[NarratorClass.properties.text] : null;

        const narr = {
            owner: owner ? owner[commonProperties.ID] : null,
            id: article[commonProperties.ID],
            title: title && title.length === 1 ? title[0][commonProperties.VALUE] : "-",
            description: description && description.length === 1 ? description[0][commonProperties.VALUE] : "-",
            narrator: {
                id: narratorId,
                text: narratorText && narratorText.length > 0 ? narratorText[0][commonProperties.VALUE] : ""
            },
            sources: [],
            entities: [],
            relations: [],
            fragments: [],
            created: created,
            modified: modified
        };

        for (const p of Object.values(ArticleClass.properties.paragraphs)) {
            if (article[p.property]) {
                const parData = article[p.property];
                for (const pData of parData) {
                    const paragraphData = data.filter(el => {
                        return el[commonProperties.ID] === pData[commonProperties.VALUE_RESOURCE_ID]
                    });
                    if (paragraphData.length === 1) {
                        let fragmentUuid = generateUniqueUuid(narr.fragments.map(el => el.uuid));

                        const paragraph = paragraphData[0];
                        const sourceRefs = paragraph[ParagraphClass.properties.sourceReference];
                        const knowledgeEnc = paragraph[ParagraphClass.properties.knowledgeEncoding];
                        const text = paragraph[ParagraphClass.properties.text];
                        const previous = paragraph[ParagraphClass.properties.previous];
                        const next = paragraph[ParagraphClass.properties.next];


                        const sourceReferences = [];
                        if (sourceRefs && sourceRefs.length > 0) {
                            for (const sourceRef of sourceRefs) {
                                const sourceRefItem = getItem(data, sourceRef[commonProperties.VALUE_RESOURCE_ID]);
                                if (sourceRefItem && sourceRefItem[commonProperties.TYPE].includes(SourceReferenceClass.class)) {
                                    const hasTitle = sourceRefItem[SourceReferenceClass.properties.title];
                                    const hasText = sourceRefItem[SourceReferenceClass.properties.text];
                                    const references = sourceRefItem[SourceReferenceClass.properties.references];
                                    const title = hasTitle && hasTitle.length === 1 ? hasTitle[0][commonProperties.VALUE] : null;
                                    const text = hasText && hasText.length === 1 ? hasText[0][commonProperties.VALUE] : null;

                                    const sourceObjects = [];
                                    if (references && references.length > 0) {
                                        for (const s of references) {
                                            const ref = getItem(data, s[commonProperties.VALUE_RESOURCE_ID]);
                                            if (ref) {
                                                const fetchedSources = alreadyExistingSources.filter(el => {
                                                    return el.id === ref[commonProperties.ID]
                                                });
                                                if (fetchedSources.length === 1) {
                                                    const fetchedSource = fetchedSources[0];
                                                    sourceObjects.push(fetchedSource.uuid);
                                                }
                                            }
                                        }
                                    }

                                    sourceReferences.push({
                                        id: sourceRefItem[commonProperties.ID],
                                        title: title,
                                        text: text,
                                        sources: sourceObjects
                                    })

                                }
                            }
                        }

                        if (knowledgeEnc && knowledgeEnc.length > 0) {
                            for (const knEnc of knowledgeEnc) {
                                const knEncItem = getItem(data, knEnc[commonProperties.VALUE_RESOURCE_ID]);
                                if (knEncItem) {
                                    knEncItemArray.push(knEncItem);
                                    const existingEntites = alreadyExistingEntities.filter(el => {
                                        return el.id === knEncItem[commonProperties.ID]
                                    });

                                    if (existingEntites.length === 1) {
                                        const existingEntity = existingEntites[0];
                                        const alreadyFetchedEntities = narr.entities.filter(el => {
                                            return el.uuid === existingEntity.uuid
                                        });
                                        if (alreadyFetchedEntities.length === 0) {
                                            //console.log("existingEntity");
                                            //console.log(existingEntity);
                                            narr.entities.push({
                                                fragmentUuid: fragmentUuid,
                                                uuid: existingEntity.uuid,
                                                selection: existingEntity.selection
                                            });
                                        }
                                    }
                                }

                            }
                        }

                        const pivot = paragraph[ParagraphClass.properties.pivot];
                        console.log("pivot", pivot);

                        const fragmentObj = {
                            uuid: fragmentUuid,
                            id: paragraph[commonProperties.ID],
                            fragment: getFragmentFromClass(paragraph[commonProperties.TYPE][1]).label,
                            text: text && text.length === 1 ? text[0][commonProperties.VALUE] : "-",
                            pivot: paragraph[ParagraphClass.properties.pivot],
                            sourceReferences: sourceReferences,
                            previousId: previous && previous.length === 1 ? previous[0][commonProperties.VALUE_RESOURCE_ID] : null,
                            nextId: next && next.length === 1 ? next[0][commonProperties.VALUE_RESOURCE_ID] : null
                        };

                        narr.fragments.push(fragmentObj);

                    }
                }
            }
        }

        //sorting fragments by previous and next
        const copiedFragments = [...narr.fragments];
        const indexOfFirstElement = copiedFragments.findIndex(el => {
            return !el.previousId
        });
        if (indexOfFirstElement !== -1) {
            let orderedFragments = copiedFragments.splice(indexOfFirstElement, 1);
            while (copiedFragments.length > 0) {
                const par = orderedFragments[orderedFragments.length - 1];
                if (par.nextId) {
                    const indexOfElement = copiedFragments.findIndex(el => {
                        return el.id === par.nextId
                    });
                    if (indexOfElement !== -1) {
                        const element = copiedFragments.splice(indexOfElement, 1)
                        if (element.length > 0) {
                            orderedFragments.push(element[0]);
                        }
                    } else {
                        orderedFragments = orderedFragments.concat(copiedFragments);
                        copiedFragments.splice(0, copiedFragments.length);
                    }
                } else {
                    orderedFragments = orderedFragments.concat(copiedFragments);
                    copiedFragments.splice(0, copiedFragments.length);
                }
            }
            narr.fragments = [...orderedFragments];
        }


        for (const entityItem of knEncItemArray) {
            for (const rel of Object.values(relations)) {
                const knEncItemRel = entityItem[rel.property];
                if (knEncItemRel && knEncItemRel.length > 0) {
                    for (const relObj of knEncItemRel) {
                        const relItem = getItem(data, relObj[commonProperties.VALUE_RESOURCE_ID]);
                        if (relItem) {
                            const fromItemArray = alreadyExistingEntities.filter(el => {
                                return el.id === entityItem[commonProperties.ID]
                            });
                            if (fromItemArray.length === 1) {
                                const fromItem = fromItemArray[0].uuid;
                                let toItem;
                                const relationObj = {
                                    author: rel.author,
                                    from: rel.from,
                                    label: rel.label,
                                    property: rel.property,
                                    to: rel.to,
                                    title: rel.comment ? rel.comment : rel.label
                                }
                                const alreadyFetchedEntities = alreadyExistingEntities.filter(el => {
                                    return el.id === relItem[commonProperties.ID]
                                });
                                if (alreadyFetchedEntities.length === 1) {
                                    toItem = alreadyFetchedEntities[0].uuid;

                                    if (fromItem && toItem) {
                                        narr.relations.push({
                                            uuid: generateUniqueUuid(narr.relations.map(el => el.relation.uuid)),
                                            from: fromItem,
                                            to: toItem,
                                            relation: {...relationObj}
                                        });
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return narr;
    }

    return null;
}

export const getEntitiesArrayData = (data, media) => {
    const items = [];
    for (const e of Object.values(entities)) {
        const filteredData = data.filter(el => {
            return el[commonProperties.TYPE].includes(e.class)
        });
        for (const knowledgeEl of filteredData) {
            const locations = [];
            if (knowledgeEl[KnowledgeClass.properties.latLong] && knowledgeEl[KnowledgeClass.properties.latLong].length > 0) {
                for (const latLngItem of knowledgeEl[KnowledgeClass.properties.latLong]) {
                    const coordinates = latLngItem[commonProperties.VALUE].trim().split(",");
                    locations.push({
                        coordinates: {
                            lat: coordinates[0],
                            lng: coordinates[1]
                        }
                    })
                }
            }
            items.push({
                uuid: generateUniqueUuid(items.map(el => el.uuid)),
                id: knowledgeEl[commonProperties.ID],
                entity: getEntityFromClass(knowledgeEl[commonProperties.TYPE][1]).label,
                selection: {
                    anchorOffset: null,
                    focusOffset: null,
                    text: knowledgeEl[commonProperties.TITLE]
                },
                locations: [...locations]
            });
        }
    }
    return items
}

export const getSourcesArrayData = (data, media) => {
    const items = [];
    for (const s of Object.values(SourceClass.sources)) {
        const filteredData = data.filter(el => {
            return el[commonProperties.TYPE].includes(s.class)
        });
        for (const sourceEl of filteredData) {
            const sourceTitle = sourceEl[SourceClass.properties.title];
            const title = sourceTitle && sourceTitle.length > 0 ? sourceTitle[0][commonProperties.VALUE] : "-";

            const sourceMedia = [];
            if (sourceEl[commonProperties.MEDIA] && sourceEl[commonProperties.MEDIA].length > 0) {
                for (const m of sourceEl[commonProperties.MEDIA]) {
                    const mediaObject = getItem(media, m[commonProperties.ID]);
                    if (mediaObject) {
                        const mediaTitle = mediaObject[MediaClass.properties.title];
                        const mTitle = mediaTitle && mediaTitle.length > 0 ? mediaTitle[0][commonProperties.VALUE] : "";

                        sourceMedia.push({
                            id: mediaObject[commonProperties.ID],
                            title: mTitle,
                            mediaType: mediaObject[commonProperties.MEDIA_TYPE],
                            mediaName: mediaObject[commonProperties.SOURCE],
                            mediaUrl: mediaObject[commonProperties.ORIGINAL_URL],
                            file: null,
                            fileData: null,
                        });
                    }
                }
            }

            items.push({
                uuid: generateUniqueUuid(items.map(el => el.uuid)),
                id: sourceEl[commonProperties.ID],
                type: getSourceFromClass(sourceEl[commonProperties.TYPE][1]).class,
                title: title,
                media: sourceMedia
            });
        }
    }
    return items
}

export const getKnowledgeArray = (data, media) => {
    const items = [];
    for (const e of Object.values(entities)) {
        const filteredData = data.filter(el => {
            return el[commonProperties.TYPE].includes(e.class)
        });
        for (const knowledgeEl of filteredData) {
            const knowledgeRelations = [];
            for (const rel of Object.values(relations)) {
                const knowledgeRel = knowledgeEl[rel.property];
                if (knowledgeRel) {
                    for (const r of knowledgeRel) {
                        knowledgeRelations.push({
                            id: r[commonProperties.VALUE_RESOURCE_ID],
                            rel: rel.label
                        });
                    }
                }
            }

            items.push({
                label: e.label,
                id: knowledgeEl[commonProperties.ID],
                text: knowledgeEl[commonProperties.TITLE],
                knowledgeRelations: knowledgeRelations
            });
        }
    }
    return items;
}

export const getMediaArray = (data) => {
    const items = [...data];
    return items;
}

const getItem = (data, id) => {
    const items = data.filter(el => {
        return el[commonProperties.ID] === id
    });
    if (items.length === 1) {
        return items[0];
    } else {
        return null;
    }
}

export const getKnowledgeItem = (data, id) => {
    const items = data.filter(el => {
        return el.id === id
    });
    if (items.length === 1) {
        return items[0];
    } else {
        return null;
    }
}

const getFragmentFromClass = (className) => {
    const items = Object.values(paragraphs).filter(el => {
        return el.class === className
    });
    if (items.length === 1) {
        return items[0];
    } else {
        return null;
    }
}

export const getFragmentFromLabel = (label) => {
    const items = Object.values(paragraphs).filter(el => {
        return el.label === label
    });
    if (items.length === 1) {
        return items[0];
    } else {
        return null;
    }
}

const getEntityFromClass = (className) => {
    const items = Object.values(entities).filter(el => {
        return el.class === className
    });
    if (items.length === 1) {
        return items[0];
    } else {
        return null;
    }
}

const getEntityFromLabel = (label) => {
    const items = Object.values(entities).filter(el => {
        return el.label === label
    });
    if (items.length === 1) {
        return items[0];
    } else {
        return null;
    }
}

const getSourceFromClass = (className) => {
    const items = Object.values(sources).filter(el => {
        return el.class === className
    });
    if (items.length === 1) {
        return items[0];
    } else {
        return null;
    }
}

/*const getSourceTypeFromLabel = (label) => {
  const items = Object.values(sources).filter(el => { return el.label === label });
  if(items.length === 1){
    return items[0];
  }
  else{
    return null;
  }
}*/

const getOmekaSDataFromClass = (data, term) => {
    const items = data.filter(el => {
        return el[commonProperties.TERM] === term
    });
    if (items.length === 1) {
        return items[0];
    } else {
        return null;
    }
}

const findIndex = (array, prop, value) => {
    for (let i = 0; i < array.length; i++) {
        const obj = array[i];
        if (obj[prop] === value) {
            return i;
        }
    }

    return -1;
};

export const getCommentFromClassOrProperty = (array, name) => {
    if (array) {
        for (const obj of array) {
            if (obj[commonProperties.TERM] === name) {
                return obj[commonProperties.COMMENT];
            }
        }
    }
    return null;
}

const checkLocationChanges = (locA, locB) => {

    if (
        (locA && !locB) ||
        (!locA && locB) ||
        (locA.length !== locB.length)
    ) {
        return true;
    }

    for (const [index, value] of Object.entries(locA)) {
        if (locB[index].coordinates.lat !== value.coordinates.lat || locB[index].coordinates.lng !== value.coordinates.lng) {
            return true;
        }
    }

    return false;
}

const getInsertRelationToEntityInFragmentKnowledgeEncodingData = (newData, r) => {
    const filteredArray = newData.insertedEntities.filter(el => {
        return el.uuid === r.from
    });
    if (filteredArray.length > 0) {
        const fIndex = findIndex(newData.insertedFragments, "uuid", filteredArray[0].fragmentUuid);
        let fragmentIndex = 0;
        let entityAlreadyAssigned = false;
        while (!entityAlreadyAssigned && fragmentIndex < newData.insertedFragments.length) {
            if (newData.insertedFragments[fragmentIndex].knowledgeEncoding && newData.insertedFragments[fragmentIndex].knowledgeEncoding.length > 0 && newData.insertedFragments[fragmentIndex].knowledgeEncoding.filter(e => {
                return e.uuid === r.to
            }).length > 0) {
                entityAlreadyAssigned = true;
            }
            fragmentIndex++;
        }
        if (!entityAlreadyAssigned && fIndex !== -1) {
            return {
                fIndex: fIndex,
                fragmentUuid: filteredArray[0].fragmentUuid,
            }
        } else {
            return null;
        }
    }
    return null;
}

export const createJSONObject = (CLASSES, PROPERTIES, title, description, author, insertedFragments, insertedEntities, insertedRelations, insertedSources, alreadyExistingEntities, alreadyExistingSources) => {

    const fragmentsToAdd = [];
    const entitiesToAdd = [];
    const relationsToAdd = [];
    const sourceReferencesToAdd = [];
    const sourcesToAdd = [];

    const omekaS_ArticleClass_data = getOmekaSDataFromClass(CLASSES, ArticleClass.class);
    const omekaS_NarratorClass_data = getOmekaSDataFromClass(CLASSES, NarratorClass.class);
    const omekaS_SourceReferenceClass_data = getOmekaSDataFromClass(CLASSES, SourceReferenceClass.class);
    const omekaS_has_title_data = getOmekaSDataFromClass(PROPERTIES, ArticleClass.properties.title);
    const omekaS_has_description_data = getOmekaSDataFromClass(PROPERTIES, ArticleClass.properties.dcTermsDescription);
    const omekaS_has_narrator_f_data = getOmekaSDataFromClass(PROPERTIES, ArticleClass.properties.narrator);
    const omekaS_has_text_data = getOmekaSDataFromClass(PROPERTIES, ParagraphClass.properties.text);
    const omekaS_Narrator_has_text_data = getOmekaSDataFromClass(PROPERTIES, NarratorClass.properties.text);
    const omekaS_has_previous_data = getOmekaSDataFromClass(PROPERTIES, ParagraphClass.properties.previous);
    const omekaS_has_next_data = getOmekaSDataFromClass(PROPERTIES, ParagraphClass.properties.next);
    const omekaS_has_knowledge_encoding_data = getOmekaSDataFromClass(PROPERTIES, ParagraphClass.properties.knowledgeEncoding);
    const omekaS_has_source_reference_data = getOmekaSDataFromClass(PROPERTIES, ParagraphClass.properties.sourceReference);
    const omekaS_references_data = getOmekaSDataFromClass(PROPERTIES, SourceReferenceClass.properties.references);
    const omekaS_latLong_data = getOmekaSDataFromClass(PROPERTIES, KnowledgeClass.properties.latLong);

    for (const s of insertedSources) {

        const sourceType = s.type ? s.type : SourceClass.sources.GENERIC_SOURCE.class;
        const omekaSClassData = getOmekaSDataFromClass(CLASSES, sourceType);

        const source = {};
        source[commonProperties.ITEM_SET] = [{[commonProperties.ID]: HSG_ITEM_SET}];
        source[commonProperties.CONTEXT] = API_CONTEXT;
        source[commonProperties.IS_PUBLIC] = true;
        source[SourceClass.properties.title] = [
            {
                [commonProperties.PROPERTY_ID]: 1,
                [commonProperties.VALUE]: s.title,
                [commonProperties.RESOURCE_TYPE]: "literal"
            }
        ];
        source[SourceClass.properties.url] = [
          {
            [commonProperties.PROPERTY_ID]: 968,
            [commonProperties.VALUE]: s.url,
            [commonProperties.RESOURCE_TYPE]: "literal",
            [commonProperties.IS_PUBLIC]: true,
            [commonProperties.PROPERTY_LABEL]: "URL"
          }
        ];
        source[commonProperties.RESOURCE_CLASS] = {[commonProperties.ID]: omekaSClassData[commonProperties.ID]};
        const sourceMedia = [];
        const filesArray = [];
        for (const [i, m] of Object.entries(s.media)) {
            const index = parseInt(i);
            sourceMedia.push({
                "o:ingester": "upload",
                "file_index": index.toString(),
                [MediaClass.properties.title]: [
                    {
                        [commonProperties.PROPERTY_ID]: 1,
                        [commonProperties.VALUE]: m.title,
                        [commonProperties.RESOURCE_TYPE]: "literal"
                    }
                ]
            })
            filesArray.push(m.file);
        }
        if (sourceMedia.length > 0) {
            source[commonProperties.MEDIA] = [...sourceMedia];
        }
        sourcesToAdd.push({
            uuid: s.uuid,
            property: s.type,
            filesArray: filesArray,
            json: source
        });
    }
    ;

    for (const e of insertedEntities) {
        if (!e.id) {
            const entityData = getEntityFromLabel(e.entity);
            const omekaSClassData = getOmekaSDataFromClass(CLASSES, entityData.class);
            const ent = {};
            ent[commonProperties.ITEM_SET] = [{[commonProperties.ID]: HSG_ITEM_SET}];
            ent[commonProperties.CONTEXT] = API_CONTEXT;
            ent[commonProperties.IS_PUBLIC] = true;
            ent[KnowledgeClass.properties.title] = [
                {
                    [commonProperties.PROPERTY_ID]: 1,
                    [commonProperties.VALUE]: e.selection.text,
                    [commonProperties.RESOURCE_TYPE]: "literal"
                }
            ];
            const locations = [];
            for (const value of e.locations) {
                locations.push({
                    [commonProperties.PROPERTY_ID]: omekaS_latLong_data[commonProperties.ID],
                    [commonProperties.VALUE]: `${value.coordinates.lat},${value.coordinates.lng}`,
                    [commonProperties.RESOURCE_TYPE]: "literal"
                })
            }
            ent[KnowledgeClass.properties.latLong] = [...locations];
            ent[commonProperties.RESOURCE_CLASS] = {[commonProperties.ID]: omekaSClassData[commonProperties.ID]};
            entitiesToAdd.push({
                action: "ADD",
                fragmentUuid: e.fragmentUuid,
                uuid: e.uuid,
                property: entityData.class,
                json: ent
            });
        } else {
            console.log("e", e);
            console.log("alreadyExistingEntities", alreadyExistingEntities);
            const entityElements = alreadyExistingEntities.filter(el => {
                return el.uuid === e.uuid
            });
            console.log("entityElements", entityElements);
            if (entityElements && entityElements.length === 1) {
                console.log("Found entity elements", entityElements);
                const entityEl = entityElements[0];
                const locationChanges = e.locations ? checkLocationChanges(e.locations, entityEl.locations) : false;
                const textChanges = e.selection.text !== entityEl.selection.text;

                if (locationChanges || textChanges) {
                    const entityData = getEntityFromLabel(entityEl.entity);
                    const omekaSClassData = getOmekaSDataFromClass(CLASSES, entityData.class);
                    const ent = {};
                    ent[commonProperties.ITEM_SET] = [{[commonProperties.ID]: HSG_ITEM_SET}];
                    ent[commonProperties.CONTEXT] = API_CONTEXT;
                    ent[commonProperties.IS_PUBLIC] = true;
                    ent[KnowledgeClass.properties.title] = [
                        {
                            [commonProperties.PROPERTY_ID]: 1,
                            [commonProperties.VALUE]: e.selection.text,
                            [commonProperties.RESOURCE_TYPE]: "literal"
                        }
                    ];
                    const locations = [];
                    for (const value of e.locations) {
                        locations.push({
                            [commonProperties.PROPERTY_ID]: omekaS_latLong_data[commonProperties.ID],
                            [commonProperties.VALUE]: `${value.coordinates.lat},${value.coordinates.lng}`,
                            [commonProperties.RESOURCE_TYPE]: "literal"
                        })
                    }
                    ent[KnowledgeClass.properties.latLong] = [...locations];
                    ent[commonProperties.RESOURCE_CLASS] = {[commonProperties.ID]: omekaSClassData[commonProperties.ID]};

                    entitiesToAdd.push({
                        fragmentUuid: e.fragmentUuid,
                        uuid: e.uuid,
                        id: entityEl.id,
                        property: entityData.class,
                        json: ent,
                        action: "EDIT"
                    });
                }
            }
        }

        const fIndex = findIndex(insertedFragments, "uuid", e.fragmentUuid);
        if (fIndex !== -1) {
            if (insertedFragments[fIndex].entities) {
                insertedFragments[fIndex].entities.push(e.uuid);
            } else {
                insertedFragments[fIndex].entities = [e.uuid];
            }
        }

    }
    ;

    for (const r of insertedRelations) {
        const omekaSPropertyData = getOmekaSDataFromClass(PROPERTIES, r.relation.property);
        relationsToAdd.push({
            from: r.from,
            to: r.to,
            relation: r.relation.property,
            relationId: omekaSPropertyData[commonProperties.ID]
        });

        const filteredArray = insertedEntities.filter(el => {
            return el.uuid === r.from
        });
        if (filteredArray.length > 0) {
            const fIndex = findIndex(insertedFragments, "uuid", filteredArray[0].fragmentUuid);
            let fragmentIndex = 0;
            let toEntityAlreadyAssigned = false;
            while (!toEntityAlreadyAssigned && fragmentIndex < insertedFragments.length) {
                if (insertedFragments[fragmentIndex].entities && insertedFragments[fragmentIndex].entities.length > 0 && insertedFragments[fragmentIndex].entities.includes(r.to)) {
                    toEntityAlreadyAssigned = true;
                }
                fragmentIndex++;
            }
            if (!toEntityAlreadyAssigned && fIndex !== -1) {
                insertedFragments[fIndex].entities.push(r.to);
            }
        }

    }
    ;

    for (const f of insertedFragments) {
        const fragmentData = getFragmentFromLabel(f.fragment);
        const omekaSClassData = getOmekaSDataFromClass(CLASSES, fragmentData.class);
        const omekaSPropertyData = getOmekaSDataFromClass(PROPERTIES, fragmentData.property);

        const frag = {};
        frag[commonProperties.ITEM_SET] = [{[commonProperties.ID]: HSG_ITEM_SET}];
        frag[commonProperties.CONTEXT] = API_CONTEXT;
        frag[commonProperties.IS_PUBLIC] = true;
        frag[ParagraphClass.properties.title] = [
            {
                [commonProperties.PROPERTY_ID]: 1,
                [commonProperties.VALUE]: `${title}:${omekaSClassData[commonProperties.LABEL]}`,
                [commonProperties.RESOURCE_TYPE]: "literal"
            }
        ];
        frag[ParagraphClass.properties.text] = [
            {
                [commonProperties.PROPERTY_ID]: omekaS_has_text_data[commonProperties.ID],
                [commonProperties.VALUE]: f.text,
                [commonProperties.RESOURCE_TYPE]: "literal"
            }
        ];
        frag[ParagraphClass.properties.pivot] = [
            {
                [commonProperties.PROPERTY_ID]: 967,
                [commonProperties.VALUE]: f.pivot && f.pivot[0] && f.pivot[0]["@value"] ? f.pivot[0]["@value"] : "false",
                [commonProperties.RESOURCE_TYPE]: "literal"
            }
        ];
        frag[commonProperties.RESOURCE_CLASS] = {[commonProperties.ID]: omekaSClassData[commonProperties.ID]};
        const sourceRefsArray = [];
        for (const sourceRef of f.sourceReferences) {
            const sourceRefObject = {};
            sourceRefObject[commonProperties.ITEM_SET] = [{[commonProperties.ID]: HSG_ITEM_SET}];
            sourceRefObject[commonProperties.CONTEXT] = API_CONTEXT;
            sourceRefObject[commonProperties.IS_PUBLIC] = true;
            sourceRefObject[SourceReferenceClass.properties.title] = [
                {
                    [commonProperties.PROPERTY_ID]: 1,
                    [commonProperties.VALUE]: sourceRef.title,
                    [commonProperties.RESOURCE_TYPE]: "literal"
                }
            ];
            sourceRefObject[SourceReferenceClass.properties.text] = [
                {
                    [commonProperties.PROPERTY_ID]: omekaS_has_text_data[commonProperties.ID],
                    [commonProperties.VALUE]: sourceRef.text,
                    [commonProperties.RESOURCE_TYPE]: "literal"
                }
            ];
            sourceRefObject[SourceReferenceClass.properties.references] = [...sourceRef.sources];
            sourceRefObject[commonProperties.RESOURCE_CLASS] = {[commonProperties.ID]: omekaS_SourceReferenceClass_data[commonProperties.ID]};

            const newUuid = generateUniqueUuid(sourceReferencesToAdd.map(el => el.uuid));
            sourceRefsArray.push(newUuid);
            sourceReferencesToAdd.push({
                uuid: newUuid,
                referencesID: omekaS_references_data[commonProperties.ID],
                json: sourceRefObject
            });
        }
        if (sourceRefsArray.length > 0) {
            frag[ParagraphClass.properties.sourceReference] = [...sourceRefsArray];
        }
        const knowledgeArray = f.entities ? [...f.entities] : [];
        if (knowledgeArray.length > 0) {
            frag[ParagraphClass.properties.knowledgeEncoding] = [...knowledgeArray];
        }
        fragmentsToAdd.push({
            uuid: f.uuid,
            property: fragmentData.property,
            propertyID: omekaSPropertyData[commonProperties.ID],
            previousPropertyId: omekaS_has_previous_data[commonProperties.ID],
            nextPropertyId: omekaS_has_next_data[commonProperties.ID],
            sourceRefPropertyId: omekaS_has_source_reference_data[commonProperties.ID],
            knowledgeEncodingPropertyId: omekaS_has_knowledge_encoding_data[commonProperties.ID],
            json: frag
        });
    }
    ;


    const narratorToAddJson = {};

    if (author) {
        narratorToAddJson[commonProperties.ITEM_SET] = [{[commonProperties.ID]: HSG_ITEM_SET}];
        narratorToAddJson[commonProperties.CONTEXT] = API_CONTEXT;
        narratorToAddJson[commonProperties.IS_PUBLIC] = true;
        narratorToAddJson[NarratorClass.properties.title] = [
            {
                [commonProperties.PROPERTY_ID]: 1,
                [commonProperties.VALUE]: `${title}:${omekaS_NarratorClass_data[commonProperties.LABEL]}`,
                [commonProperties.RESOURCE_TYPE]: "literal"
            }
        ];
        narratorToAddJson[NarratorClass.properties.text] = [
            {
                [commonProperties.PROPERTY_ID]: omekaS_Narrator_has_text_data[commonProperties.ID],
                [commonProperties.VALUE]: author.text,
                [commonProperties.RESOURCE_TYPE]: "literal"
            }
        ];

        narratorToAddJson[commonProperties.RESOURCE_CLASS] = {[commonProperties.ID]: omekaS_NarratorClass_data[commonProperties.ID]};

    }

    const narratorToAdd = author ? {
        json: narratorToAddJson,
        propertyId: omekaS_has_narrator_f_data[commonProperties.ID]
    } : null;

    const narrationObj = {};

    narrationObj[commonProperties.ITEM_SET] = [{[commonProperties.ID]: HSG_ITEM_SET}];
    narrationObj[commonProperties.CONTEXT] = API_CONTEXT;
    narrationObj[commonProperties.IS_PUBLIC] = true;
    narrationObj[ArticleClass.properties.dcTermsTitle] = [
        {
            [commonProperties.PROPERTY_ID]: 1,
            [commonProperties.VALUE]: title,
            [commonProperties.RESOURCE_TYPE]: "literal"
        }
    ];
    narrationObj[ArticleClass.properties.dcTermsDescription] = [
        {
            [commonProperties.PROPERTY_ID]: 4,
            [commonProperties.VALUE]: description,
            [commonProperties.RESOURCE_TYPE]: "literal"
        }
    ];

    narrationObj[ArticleClass.properties.title] = [
        {
            [commonProperties.PROPERTY_ID]: omekaS_has_title_data[commonProperties.ID],
            [commonProperties.VALUE]: title,
            [commonProperties.RESOURCE_TYPE]: "literal"
        }
    ];

    narrationObj[commonProperties.RESOURCE_CLASS] = {[commonProperties.ID]: omekaS_ArticleClass_data[commonProperties.ID]};

    return {
        sourcesToAdd,
        sourceReferencesToAdd,
        entitiesToAdd,
        relationsToAdd,
        fragmentsToAdd,
        narratorToAdd,
        narrationObj
    }
}

export const createJSONObjectForEdit = (CLASSES, PROPERTIES, previousData, newData, alreadyExistingEntities, alreadyExistingSources) => {

    //const omekaS_ArticleClass_data = getOmekaSDataFromClass(CLASSES, ArticleClass.class);
    const omekaS_NarratorClass_data = getOmekaSDataFromClass(CLASSES, NarratorClass.class);
    const omekaS_SourceReferenceClass_data = getOmekaSDataFromClass(CLASSES, SourceReferenceClass.class);
    const omekaS_has_title_data = getOmekaSDataFromClass(PROPERTIES, ArticleClass.properties.title);
    const omekaS_has_narrator_f_data = getOmekaSDataFromClass(PROPERTIES, ArticleClass.properties.narrator);
    const omekaS_has_text_data = getOmekaSDataFromClass(PROPERTIES, ParagraphClass.properties.text);
    const omekaS_has_pivot_data = getOmekaSDataFromClass(PROPERTIES, ParagraphClass.properties.pivot);
    console.log("omekaS_has_pivot_data", omekaS_has_pivot_data);
    const omekaS_Narrator_has_text_data = getOmekaSDataFromClass(PROPERTIES, NarratorClass.properties.text);
    const omekaS_has_previous_data = getOmekaSDataFromClass(PROPERTIES, ParagraphClass.properties.previous);
    const omekaS_has_next_data = getOmekaSDataFromClass(PROPERTIES, ParagraphClass.properties.next);
    const omekaS_has_knowledge_encoding_data = getOmekaSDataFromClass(PROPERTIES, ParagraphClass.properties.knowledgeEncoding);
    const omekaS_has_source_reference_data = getOmekaSDataFromClass(PROPERTIES, ParagraphClass.properties.sourceReference);
    const omekaS_references_data = getOmekaSDataFromClass(PROPERTIES, SourceReferenceClass.properties.references);
    const omekaS_latLong_data = getOmekaSDataFromClass(PROPERTIES, KnowledgeClass.properties.latLong);
    const omekaS_has_url_data = getOmekaSDataFromClass(PROPERTIES, SourceClass.properties.url);

    let narratorToEdit = {};
    if ((newData && newData.narrator && newData.narrator.id) && (!newData.narrator.text || newData.narrator.text === "")) {
        narratorToEdit = null;
    } else if (
        (previousData.title && newData.title && previousData.title !== newData.title) ||
        (!previousData.narrator && newData.narrator) ||
        (previousData.narrator && newData.narrator && previousData.narrator.text !== newData.narrator.text)
    ) {
        narratorToEdit.id = previousData.narrator ? previousData.narrator.id : null;

        const narratorToEditJson = {};
        narratorToEditJson[commonProperties.ITEM_SET] = [{[commonProperties.ID]: HSG_ITEM_SET}];
        narratorToEditJson[commonProperties.CONTEXT] = API_CONTEXT;
        narratorToEditJson[commonProperties.IS_PUBLIC] = true;
        narratorToEditJson[NarratorClass.properties.title] = [
            {
                [commonProperties.PROPERTY_ID]: 1,
                [commonProperties.VALUE]: `${newData.title}:${omekaS_NarratorClass_data[commonProperties.LABEL]}`,
                [commonProperties.RESOURCE_TYPE]: "literal"
            }
        ];
        narratorToEditJson[NarratorClass.properties.text] = [
            {
                [commonProperties.PROPERTY_ID]: omekaS_Narrator_has_text_data[commonProperties.ID],
                [commonProperties.VALUE]: newData.narrator.text,
                [commonProperties.RESOURCE_TYPE]: "literal"
            }
        ];
        narratorToEditJson[commonProperties.RESOURCE_CLASS] = {[commonProperties.ID]: omekaS_NarratorClass_data[commonProperties.ID]};

        narratorToEdit.json = {...narratorToEditJson};
        narratorToEdit.propertyId = omekaS_has_narrator_f_data[commonProperties.ID]

    } else {
        narratorToEdit = null;
    }


    let titleToEdit = {};
    if (newData.title && previousData.title && newData.title !== previousData.title) {
        titleToEdit.propertyId = omekaS_has_title_data[commonProperties.ID];
        titleToEdit.text = newData.title;
    } else {
        titleToEdit = null;
    }

    let descriptionToEdit = {};
    if (newData.description && previousData.description && newData.description !== previousData.description) {
        descriptionToEdit.propertyId = 4;
        descriptionToEdit.text = newData.description;
    } else {
        descriptionToEdit = null;
    }

    const sourcesToEdit = [];
    for (const s of newData.insertedSources) {
        if (typeof s !== "string") {
            const sourceType = s.type ? s.type : SourceClass.sources.GENERIC_SOURCE.class;
            const omekaSClassData = getOmekaSDataFromClass(CLASSES, sourceType);

            const source = {};
            source[commonProperties.ITEM_SET] = [{[commonProperties.ID]: HSG_ITEM_SET}];
            source[commonProperties.CONTEXT] = API_CONTEXT;
            source[commonProperties.IS_PUBLIC] = true;
            source[SourceClass.properties.title] = [
                {
                    [commonProperties.PROPERTY_ID]: 1,
                    [commonProperties.VALUE]: s.title,
                    [commonProperties.RESOURCE_TYPE]: "literal"
                }
            ];
            source[SourceClass.properties.url] = [
                {
                    [commonProperties.PROPERTY_ID]: 968,
                    [commonProperties.VALUE]: s.url,
                    [commonProperties.RESOURCE_TYPE]: "literal",
                    [commonProperties.IS_PUBLIC]: true,
                    [commonProperties.PROPERTY_LABEL]: "URL"
                }
            ];
            source[commonProperties.RESOURCE_CLASS] = {[commonProperties.ID]: omekaSClassData[commonProperties.ID]};
            const sourceMedia = [];
            const filesArray = [];
            for (const [i, m] of Object.entries(s.media)) {
                const index = parseInt(i);
                sourceMedia.push({
                    "o:ingester": "upload",
                    "file_index": index.toString(),
                    [MediaClass.properties.title]: [
                        {
                            [commonProperties.PROPERTY_ID]: 1,
                            [commonProperties.VALUE]: m.title,
                            [commonProperties.RESOURCE_TYPE]: "literal"
                        }
                    ]
                })
                filesArray.push(m.file);
            }
            if (sourceMedia.length > 0) {
                source[commonProperties.MEDIA] = [...sourceMedia];
            }
            sourcesToEdit.push({
                uuid: s.uuid,
                property: s.type,
                filesArray: filesArray,
                json: source
            });
        }
    }

    const entitiesToEdit = [];
    for (const e of newData.insertedEntities) {
        if (e.entity && !e.id) {
            const entityData = getEntityFromLabel(e.entity);
            const omekaSClassData = getOmekaSDataFromClass(CLASSES, entityData.class);
            const ent = {};
            ent[commonProperties.ITEM_SET] = [{[commonProperties.ID]: HSG_ITEM_SET}];
            ent[commonProperties.CONTEXT] = API_CONTEXT;
            ent[commonProperties.IS_PUBLIC] = true;
            ent[KnowledgeClass.properties.title] = [
                {
                    [commonProperties.PROPERTY_ID]: 1,
                    [commonProperties.VALUE]: e.selection.text,
                    [commonProperties.RESOURCE_TYPE]: "literal"
                }
            ];
            const locations = [];
            for (const value of e.locations) {
                locations.push({
                    [commonProperties.PROPERTY_ID]: omekaS_latLong_data[commonProperties.ID],
                    [commonProperties.VALUE]: `${value.coordinates.lat},${value.coordinates.lng}`,
                    [commonProperties.RESOURCE_TYPE]: "literal"
                })
            }
            ent[KnowledgeClass.properties.latLong] = [...locations];
            ent[commonProperties.RESOURCE_CLASS] = {[commonProperties.ID]: omekaSClassData[commonProperties.ID]};

            const fragmentIndex = newData.insertedFragments.findIndex(el => {
                return el.uuid === e.fragmentUuid
            })
            if (fragmentIndex !== -1) {
                const frag = {...newData.insertedFragments[fragmentIndex]};
                if (frag.knowledgeEncoding) {
                    const entityAlreadyAdded = frag.knowledgeEncoding.findIndex(el => {
                        return el.uuid === e.uuid
                    }) !== -1;
                    if (!entityAlreadyAdded) {
                        frag.knowledgeEncoding.push(e);
                    }
                } else {
                    frag.knowledgeEncoding = [e];
                }
                newData.insertedFragments[fragmentIndex] = {...frag};

            }

            entitiesToEdit.push({
                fragmentUuid: e.fragmentUuid,
                uuid: e.uuid,
                property: entityData.class,
                knowledgeEncodingPropertyId: omekaS_has_knowledge_encoding_data[commonProperties.ID],
                json: ent,
                action: "ADD"
            });

        } else {
            const entityElements = alreadyExistingEntities.filter(el => {
                return el.uuid === e.uuid
            });
            console.log("entityElements", entityElements);
            if (entityElements && entityElements.length === 1) {
                console.log("Found entity elements", entityElements);
                const entityEl = entityElements[0];
                const fragmentIndex = newData.insertedFragments.findIndex(el => {
                    return el.uuid === e.fragmentUuid
                })
                if (fragmentIndex !== -1) {
                    const frag = {...newData.insertedFragments[fragmentIndex]};
                    if (frag.knowledgeEncoding) {
                        const entityAlreadyAdded = frag.knowledgeEncoding.findIndex(el => {
                            return el.uuid === e.uuid
                        }) !== -1;
                        if (!entityAlreadyAdded) {
                            frag.knowledgeEncoding.push(e);
                        }
                    } else {
                        frag.knowledgeEncoding = [e];
                    }
                    newData.insertedFragments[fragmentIndex] = {...frag};
                }

                const locationChanges = e.locations ? checkLocationChanges(e.locations, entityEl.locations) : false;
                const textChanges = e.selection.text !== entityEl.selection.text;

                if (locationChanges || textChanges) {
                    const entityData = getEntityFromLabel(entityEl.entity);
                    const omekaSClassData = getOmekaSDataFromClass(CLASSES, entityData.class);
                    const ent = {};
                    ent[commonProperties.ITEM_SET] = [{[commonProperties.ID]: HSG_ITEM_SET}];
                    ent[commonProperties.CONTEXT] = API_CONTEXT;
                    ent[commonProperties.IS_PUBLIC] = true;
                    ent[KnowledgeClass.properties.title] = [
                        {
                            [commonProperties.PROPERTY_ID]: 1,
                            [commonProperties.VALUE]: e.selection.text,
                            [commonProperties.RESOURCE_TYPE]: "literal"
                        }
                    ];
                    const locations = [];
                    for (const value of e.locations) {
                        locations.push({
                            [commonProperties.PROPERTY_ID]: omekaS_latLong_data[commonProperties.ID],
                            [commonProperties.VALUE]: `${value.coordinates.lat},${value.coordinates.lng}`,
                            [commonProperties.RESOURCE_TYPE]: "literal"
                        })
                    }
                    ent[KnowledgeClass.properties.latLong] = [...locations];
                    ent[commonProperties.RESOURCE_CLASS] = {[commonProperties.ID]: omekaSClassData[commonProperties.ID]};

                    entitiesToEdit.push({
                        fragmentUuid: e.fragmentUuid,
                        uuid: e.uuid,
                        id: entityEl.id,
                        property: entityData.class,
                        knowledgeEncodingPropertyId: omekaS_has_knowledge_encoding_data[commonProperties.ID],
                        json: ent,
                        action: "EDIT"
                    });
                } else {
                    entitiesToEdit.push({
                        fragmentUuid: e.fragmentUuid,
                        uuid: e.uuid,
                        id: entityEl.id,
                        action: "-"
                    });
                }

            }

        }
    }

    const relationsToEdit = [];
    const previousRelations = [...previousData.relations];
    for (const r of newData.insertedRelations) {
        const filteredPreviousRelations = previousData.relations.filter(el => {
            return el.uuid === r.uuid
        });
        if (filteredPreviousRelations.length === 1) {
            const previousRel = filteredPreviousRelations[0];
            if (previousRel.from !== r.from || previousRel.to !== r.to) {
                if (previousRel.from !== r.from && previousRel.to === r.to) {
                    const omekaSPropertyData = getOmekaSDataFromClass(PROPERTIES, r.relation.property);
                    relationsToEdit.push({
                        from: previousRel.from,
                        to: r.to,
                        action: "REMOVE",
                        relationId: omekaSPropertyData[commonProperties.ID],
                        property: r.relation.property
                    })
                    relationsToEdit.push({
                        from: r.from,
                        to: r.to,
                        action: "ADD",
                        relationId: omekaSPropertyData[commonProperties.ID],
                        property: r.relation.property
                    })
                } else if (previousRel.from === r.from && previousRel.to !== r.to) {
                    const omekaSPropertyData = getOmekaSDataFromClass(PROPERTIES, r.relation.property);
                    relationsToEdit.push({
                        from: r.from,
                        to: r.to,
                        oldTo: previousRel.to,
                        action: "EDIT",
                        relationId: omekaSPropertyData[commonProperties.ID],
                        property: r.relation.property
                    })
                    const toData = getInsertRelationToEntityInFragmentKnowledgeEncodingData(newData, r);
                    if (toData) {
                        newData.insertedFragments[toData.fIndex].knowledgeEncoding.push({
                            fragmentUuid: toData.fragmentUuid,
                            uuid: r.to
                        });
                    }
                } else if (previousRel.from !== r.from && previousRel.to !== r.to) {
                    const omekaSPropertyData = getOmekaSDataFromClass(PROPERTIES, r.relation.property);
                    relationsToEdit.push({
                        from: previousRel.from,
                        to: previousRel.to,
                        action: "REMOVE",
                        relationId: omekaSPropertyData[commonProperties.ID],
                        property: r.relation.property
                    })
                    relationsToEdit.push({
                        from: r.from,
                        to: r.to,
                        action: "ADD",
                        relationId: omekaSPropertyData[commonProperties.ID],
                        property: r.relation.property
                    })
                    const toData = getInsertRelationToEntityInFragmentKnowledgeEncodingData(newData, r);
                    if (toData) {
                        newData.insertedFragments[toData.fIndex].knowledgeEncoding.push({
                            fragmentUuid: toData.fragmentUuid,
                            uuid: r.to
                        });
                    }
                } else {
                }
            }
            const index = previousRelations.findIndex(el => {
                return el.uuid === previousRel.uuid
            });
            if (index !== -1) {
                previousRelations.splice(index, 1);
            }
        } else {
            const omekaSPropertyData = getOmekaSDataFromClass(PROPERTIES, r.relation.property);
            relationsToEdit.push({
                from: r.from,
                to: r.to,
                action: "ADD",
                relationId: omekaSPropertyData[commonProperties.ID],
                property: r.relation.property
            })
            const toData = getInsertRelationToEntityInFragmentKnowledgeEncodingData(newData, r);
            if (toData) {
                newData.insertedFragments[toData.fIndex].knowledgeEncoding.push({
                    fragmentUuid: toData.fragmentUuid,
                    uuid: r.to
                });
            }
        }

    }
    ;
    for (const r of previousRelations) {
        const omekaSPropertyData = getOmekaSDataFromClass(PROPERTIES, r.relation.property);
        relationsToEdit.push({
            from: r.from,
            to: r.to,
            action: "REMOVE",
            relationId: omekaSPropertyData[commonProperties.ID],
            property: r.relation.property
        })
    }

    const fragmentsToEdit = [];
    const sourceReferencesToEdit = [];
    const previousFragments = [...previousData.fragments];
    for (const [i, f] of Object.entries(newData.insertedFragments)) {
        const index = parseInt(i);
        const fragmentData = getFragmentFromLabel(f.fragment);
        const omekaSClassData = getOmekaSDataFromClass(CLASSES, fragmentData.class);
        const omekaSPropertyData = getOmekaSDataFromClass(PROPERTIES, fragmentData.property);
        const previousUuid = index === 0 ? null : newData.insertedFragments[index - 1].uuid;
        const nextUuid = index === newData.insertedFragments.length - 1 ? null : newData.insertedFragments[index + 1].uuid;
        const filteredOldFragments = previousData.fragments.filter(el => {
            return el.uuid === f.uuid
        });
        if (filteredOldFragments.length === 1) {
            const oldFrag = filteredOldFragments[0];

            const fragToEditJson = {};
            fragToEditJson[commonProperties.ITEM_SET] = [{[commonProperties.ID]: HSG_ITEM_SET}];
            fragToEditJson[commonProperties.CONTEXT] = API_CONTEXT;
            fragToEditJson[commonProperties.IS_PUBLIC] = true;
            fragToEditJson[ParagraphClass.properties.title] = [
                {
                    [commonProperties.PROPERTY_ID]: 1,
                    [commonProperties.VALUE]: `${newData.title}:${omekaSClassData[commonProperties.LABEL]}`,
                    [commonProperties.RESOURCE_TYPE]: "literal"
                }
            ];
            fragToEditJson[ParagraphClass.properties.text] = [
                {
                    [commonProperties.PROPERTY_ID]: omekaS_has_text_data[commonProperties.ID],
                    [commonProperties.VALUE]: f.text,
                    [commonProperties.RESOURCE_TYPE]: "literal"
                }
            ];
            fragToEditJson[ParagraphClass.properties.pivot] = [
                {
                    [commonProperties.PROPERTY_ID]: 967,
                    [commonProperties.VALUE]: f.pivot && f.pivot[0] && f.pivot[0]["@value"] ? f.pivot[0]["@value"] : "false",
                    [commonProperties.RESOURCE_TYPE]: "literal"
                }
            ];
            fragToEditJson[commonProperties.RESOURCE_CLASS] = {[commonProperties.ID]: omekaSClassData[commonProperties.ID]};

            //source References
            const sourceRefsArray = [];
            const oldSourceReferences = [...oldFrag.sourceReferences];
            const currentSourceReferences = [...f.sourceReferences];

            for (const sf of currentSourceReferences) {
                const sourceRefObject = {};
                sourceRefObject[commonProperties.ITEM_SET] = [{[commonProperties.ID]: HSG_ITEM_SET}];
                sourceRefObject[commonProperties.CONTEXT] = API_CONTEXT;
                sourceRefObject[commonProperties.IS_PUBLIC] = true;
                sourceRefObject[SourceReferenceClass.properties.title] = [
                    {
                        [commonProperties.PROPERTY_ID]: 1,
                        [commonProperties.VALUE]: sf.title,
                        [commonProperties.RESOURCE_TYPE]: "literal"
                    }
                ];
                sourceRefObject[SourceReferenceClass.properties.text] = [
                    {
                        [commonProperties.PROPERTY_ID]: omekaS_has_text_data[commonProperties.ID],
                        [commonProperties.VALUE]: sf.text,
                        [commonProperties.RESOURCE_TYPE]: "literal"
                    }
                ];
                sourceRefObject[SourceReferenceClass.properties.references] = [...sf.sources];
                sourceRefObject[commonProperties.RESOURCE_CLASS] = {[commonProperties.ID]: omekaS_SourceReferenceClass_data[commonProperties.ID]};

                const uuid = generateUniqueUuid(sourceReferencesToEdit.map(el => el.uuid));
                sourceReferencesToEdit.push({
                    uuid: uuid,
                    id: sf.id,
                    fragmentUuid: f.uuid,
                    referencesID: omekaS_references_data[commonProperties.ID],
                    json: sourceRefObject,
                    action: sf.id ? "EDIT" : "ADD"
                });
                sourceRefsArray.push(uuid);

                if (sf.id) {
                    const filteredOldSourceRefs = oldFrag.sourceReferences.filter(el => {
                        return el.id === sf.id
                    });
                    if (filteredOldSourceRefs.length === 1) {
                        const oldSourceRef = filteredOldSourceRefs[0];
                        const index = oldSourceReferences.findIndex(el => {
                            return el.uuid === oldSourceRef.uuid
                        });
                        if (index !== -1) {
                            oldSourceReferences.splice(index, 1);
                        }
                    }
                }

            }
            for (const sf of oldSourceReferences) {
                sourceReferencesToEdit.push({
                    id: sf.id,
                    fragmentUuid: f.uuid,
                    action: "REMOVE"
                })
            }


            //knowledge
            const knowledgeEncodingArray = [];
            const currentKnowledgeEnc = f.knowledgeEncoding ? [...f.knowledgeEncoding] : [];
            for (const ke of currentKnowledgeEnc) {
                knowledgeEncodingArray.push(ke.uuid);
            }

            fragToEditJson[ParagraphClass.properties.sourceReference] = [...sourceRefsArray];
            fragToEditJson[ParagraphClass.properties.knowledgeEncoding] = [...knowledgeEncodingArray];

            const fIndex = previousFragments.findIndex(el => {
                return el.uuid === oldFrag.uuid
            });
            if (fIndex !== -1) {
                previousFragments.splice(fIndex, 1);
            }

            fragmentsToEdit.push({
                id: f.id,
                uuid: f.uuid,
                property: fragmentData.property,
                propertyID: omekaSPropertyData[commonProperties.ID],
                previousUuid: previousUuid,
                nextUuid: nextUuid,
                previousPropertyId: omekaS_has_previous_data[commonProperties.ID],
                nextPropertyId: omekaS_has_next_data[commonProperties.ID],
                sourceRefPropertyId: omekaS_has_source_reference_data[commonProperties.ID],
                knowledgeEncodingPropertyId: omekaS_has_knowledge_encoding_data[commonProperties.ID],
                json: fragToEditJson,
                action: "EDIT"
            });
        } else {
            const fragToEditJson = {};
            fragToEditJson[commonProperties.ITEM_SET] = [{[commonProperties.ID]: HSG_ITEM_SET}];
            fragToEditJson[commonProperties.CONTEXT] = API_CONTEXT;
            fragToEditJson[commonProperties.IS_PUBLIC] = true;
            fragToEditJson[ParagraphClass.properties.title] = [
                {
                    [commonProperties.PROPERTY_ID]: 1,
                    [commonProperties.VALUE]: `${newData.title}:${omekaSClassData[commonProperties.LABEL]}`,
                    [commonProperties.RESOURCE_TYPE]: "literal"
                }
            ];
            fragToEditJson[ParagraphClass.properties.text] = [
                {
                    [commonProperties.PROPERTY_ID]: omekaS_has_text_data[commonProperties.ID],
                    [commonProperties.VALUE]: f.text,
                    [commonProperties.RESOURCE_TYPE]: "literal"
                }
            ];

            fragToEditJson[ParagraphClass.properties.pivot] = [
                {
                    [commonProperties.PROPERTY_ID]: 967,
                    [commonProperties.VALUE]: f.pivot && f.pivot[0] && f.pivot[0]["@value"] ? f.pivot[0]["@value"] : "false",
                    [commonProperties.RESOURCE_TYPE]: "literal"
                }
            ];
            fragToEditJson[commonProperties.RESOURCE_CLASS] = {[commonProperties.ID]: omekaSClassData[commonProperties.ID]};

            //source References
            const sourceRefsArray = [];
            const currentSourceReferences = [...f.sourceReferences];

            for (const sf of currentSourceReferences) {
                const sourceRefObject = {};
                sourceRefObject[commonProperties.ITEM_SET] = [{[commonProperties.ID]: HSG_ITEM_SET}];
                sourceRefObject[commonProperties.CONTEXT] = API_CONTEXT;
                sourceRefObject[commonProperties.IS_PUBLIC] = true;
                sourceRefObject[SourceReferenceClass.properties.title] = [
                    {
                        [commonProperties.PROPERTY_ID]: 1,
                        [commonProperties.VALUE]: sf.title,
                        [commonProperties.RESOURCE_TYPE]: "literal"
                    }
                ];
                sourceRefObject[SourceReferenceClass.properties.text] = [
                    {
                        [commonProperties.PROPERTY_ID]: omekaS_has_text_data[commonProperties.ID],
                        [commonProperties.VALUE]: sf.text,
                        [commonProperties.RESOURCE_TYPE]: "literal"
                    }
                ];
                sourceRefObject[SourceReferenceClass.properties.references] = [...sf.sources];
                sourceRefObject[commonProperties.RESOURCE_CLASS] = {[commonProperties.ID]: omekaS_SourceReferenceClass_data[commonProperties.ID]};

                const uuid = generateUniqueUuid(sourceReferencesToEdit.map(el => el.uuid));
                sourceReferencesToEdit.push({
                    uuid: uuid,
                    fragmentUuid: f.uuid,
                    referencesID: omekaS_references_data[commonProperties.ID],
                    json: sourceRefObject,
                    action: "ADD"
                });
                sourceRefsArray.push(uuid);

            }

            //knowledge
            const knowledgeEncodingArray = [];
            const currentKnowledgeEnc = f.knowledgeEncoding ? [...f.knowledgeEncoding] : [];

            for (const ke of currentKnowledgeEnc) {
                knowledgeEncodingArray.push(ke.uuid);
            }

            fragToEditJson[ParagraphClass.properties.sourceReference] = [...sourceRefsArray];
            fragToEditJson[ParagraphClass.properties.knowledgeEncoding] = [...knowledgeEncodingArray];

            fragmentsToEdit.push({
                uuid: f.uuid,
                property: fragmentData.property,
                propertyID: omekaSPropertyData[commonProperties.ID],
                previousUuid: previousUuid,
                nextUuid: nextUuid,
                previousPropertyId: omekaS_has_previous_data[commonProperties.ID],
                nextPropertyId: omekaS_has_next_data[commonProperties.ID],
                sourceRefPropertyId: omekaS_has_source_reference_data[commonProperties.ID],
                knowledgeEncodingPropertyId: omekaS_has_knowledge_encoding_data[commonProperties.ID],
                json: fragToEditJson,
                action: "ADD"
            });
        }
    }
    for (const p of previousFragments) {
        fragmentsToEdit.push({
            id: p.id,
            action: "REMOVE"
        });
    }

    return {
        narrationId: newData.id,
        titleToEdit,
        descriptionToEdit,
        sourcesToEdit,
        sourceReferencesToEdit,
        entitiesToEdit,
        relationsToEdit,
        fragmentsToEdit,
        narratorToEdit
    }
}
import React, { useEffect, useImperativeHandle, forwardRef, useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';

import classes from './NarrationMap.module.css';

import MARKER from '../../assets/images/markerIcon.svg';
import INACTIVE_MARKER from '../../assets/images/markerIconInactive.svg';

const NarrationMap = forwardRef((props, ref) => {

    const {
        narration,
        knowledge,
        media
    } = props;

    const [map, setMap] = useState(null);

    useImperativeHandle(ref, () => ({
        centerMap(latLngBounds) {
            if (map) {
                map.setView(latLngBounds.getCenter());
            }
        },
        getMapInstance() {
            return map;
        }
    }));

    useEffect(() => {
        if (!map) {
            const lat = narration.locations?.[0]?.lat || 44.20279874695161;
            const lng = narration.locations?.[0]?.lng || 7.575759887695313;
            const mapInstance = L.map('leaflet-map', { zoomControl: false }).setView([lat, lng], 13);
            L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
                attribution: '&copy; OpenStreetMap contributors &copy; CARTO',
                subdomains: 'abcd',
                maxZoom: 20
            }).addTo(mapInstance);
            setMap(mapInstance);
        }
    }, [map]);

    useEffect(() => {
        if (map && narration.locations) {
            const offset = 0.0001; // Base offset to slightly change coordinates
            console.log("narration", narration);
            console.log("knowledge", knowledge);

            const locations = narration.locations.map(location => {
                return {
                    id: location.entityId,
                    name: location.entity,
                    lat: parseFloat(location.lat),
                    lng: parseFloat(location.lng),
                }
            });

            console.log("locations", locations);

            const locationsKnowledge = knowledge.filter(item => {
                return locations.some(location => location.id === item.id);
            });

            console.log("locationsKnowledge", locationsKnowledge);

            const narrationEntities = [];
            narration.fragments.map(fragment => {
                fragment.knowledgeEncoding.forEach(knowledgeEncoding => {
                    narrationEntities.push(knowledgeEncoding);
                })
            });
            console.log("narrationEntities", narrationEntities);

            let addedNewLocations;
            const radius = 0.0001; // Base radius for circular pattern
            const angleIncrement = 25; // Angle increment in degrees
            do {
                addedNewLocations = false;
                knowledge.forEach((item, index) => {
                    if (narrationEntities.includes(item.id)) {
                        if(item.id === 3844){
                            console.log("item", item);
                        }
                        const locationRelations = item.knowledgeRelations.filter(rel =>
                            rel.rel === 'fallsWithin' || rel.rel === 'hasCurrentPermanentLocation' || rel.rel === 'isCurrentPermanentLocationOf' || rel.rel === 'contains'
                        );
                        if (locationRelations) {
                            locationRelations.forEach(locationRelation => {
                                const relatedLocation = locations.find(loc => loc.id === locationRelation.id);
                                if (relatedLocation && !locations.some(loc => loc.id === item.id)) {
                                    let lat = parseFloat(relatedLocation.lat);
                                    let lng = parseFloat(relatedLocation.lng);
                                    let angle = 0;

                                    // Check if coordinates already exist
                                    while (locations.some(loc => loc.lat === lat && loc.lng === lng)) {
                                        angle += angleIncrement;
                                        lat = parseFloat(relatedLocation.lat) + radius * Math.cos(angle * Math.PI / 180);
                                        lng = parseFloat(relatedLocation.lng) + radius * Math.sin(angle * Math.PI / 180);
                                    }

                                    locations.push({ id: item.id, name: item.text, lat: lat, lng: lng });
                                    addedNewLocations = true;
                                }
                            });
                        }
                    }
                });
            } while (addedNewLocations);
            console.log("locations", locations);

            const activeIcon = L.icon({
                iconUrl: INACTIVE_MARKER,
                iconSize: [20, 20], // size of the icon
                iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
                popupAnchor: [1, -34], // point from which the popup should open relative to the iconAnchor
            });

            locations.forEach(location => {
                L.marker([location.lat, location.lng], {icon: activeIcon})
                    .addTo(map)
                    .bindPopup(`<b>${location.name}</b>`)

            });

            //fit map to bounds
            const bounds = locations.map(location => [location.lat, location.lng]);
            map.fitBounds(bounds, {padding: [50, 50], maxZoom: 12 });
        }
    }, [map, narration]);

    return (
        <div>
            <div id="leaflet-map" className={classes.LeafletMap}></div>
        </div>
    );
});

export default NarrationMap;